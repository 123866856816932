<template>
	<div>
		<TopTabBar :hidebelowtabs="true">
			<template v-slot:belowtabs></template>
		</TopTabBar>
		<div class="body-hold hide-below-tabs">
			<div v-if="reportType === 'multiRank'" style="margin-bottom: 10px;">
				<p class="red">Please choose ONE survey for Multi-Station Rank Report.</p>
			</div>
			<div v-if="reportType === 'duplicationVenn'" style="margin-bottom: 10px;">
				<p class="red">Please choose ONE actual survey or ONE relative survey (ie Latest, Metro Equiv S2 2024) for Duplication Venn Report.</p>
			</div>
			<div class="view-holder nostyle">
				<div v-if="store.selectionObs.markets.length === 0">
					<p>Please choose at least one market first</p>
				</div>
				<ListsChooser v-else v-on:filterupdate="filterText = $event">
					<template v-slot:interior_tabs>
						<InteriorTab
							id="surveys" name="Surveys" :isactive="groupview === 'surveys'" @set="groupview = $event"
							title="Select specific individual surveys for each market"
						></InteriorTab>
						<InteriorTab
							id="relative" name="Relative" :isactive="groupview === 'relative'" @set="groupview = $event"
							title="Select relative dated surveys eg 'Latest' or 'Latest - 1' to apply to all chosen markets"
						></InteriorTab>
						<InteriorTab
							id="relmetro" name="Metro Equiv" :isactive="groupview === 'relmetro'" @set="groupview = $event"
							title="Select the survey that was active at the equivalent time as a GFK metro survey.  Eg choose GF202203 to select the latest survey in each chosen market that was active at the time s3 2022 was released for SMBAP.  This can be useful for dated snapshots across markets that don't have surveys at exactly the same time"
						></InteriorTab>
						<InteriorTab id="siteuser" name="User" :isactive="groupview === 'siteuser'" @set="groupview = $event"></InteriorTab>
					</template>
					<template v-slot:left_items>
						<div v-if="groupview === 'surveys'">
							<SurveysMarketGroup v-for="mkt in store.selectionObs.markets" :mktid="mkt.id" :key="mkt.id"
								:filtertext="filterText" :hiddenmarketids="hiddenMarketIds" :allsurveysactive="allSurveysActive"
								v-on:toggleviewed="toggleviewed($event)"
								v-on:checktoggle="checkSurveyAccessThenToggle($event)"
							></SurveysMarketGroup>
						</div>
						<div v-if="groupview === 'relative'">
							<div v-for="svc in store.relativeLatestSurveyObs" :key="svc.id" class="ch-and-title"
								:class="{surveyIsChosen : allSurveysActive.includes(svc)}"
								@mousedown.left="addSurveyToCorrectGroup(svc)" @mouseover="selectionToggleDrag(svc)"
							>
								<span class="rel-label">{{svc.name}}</span>
							</div>
						</div>
						<div v-if="groupview === 'relmetro'">
							<div v-for="svc in store.metroEquivalentSurveyObs" :key="svc.id" class="ch-and-title"
								:class="{surveyIsChosen : allSurveysActive.includes(svc)}"
								@mousedown.left="addSurveyToCorrectGroup(svc)" @mouseover="selectionToggleDrag(svc)"
							>
								<span class="rel-label">Metro {{svc.id}}</span>
							</div>
						</div>
						<div v-if="groupview === 'siteuser'" style="margin-top: -10px;">
							<p style="margin-bottom: 10px;">Create custom folders in the right panel then save.  Your saved survey combo/folders will show here.</p>
							<div v-for="svc in filteredSurveyCombos" :key="svc.id" class="ch-and-title sgroup"
								:class="{surveyIsChosen : allSurveysActive.includes(svc)}"
								@mousedown.left="addSavedGroupToList(svc)" @mouseover="addSavedGroupToListDrag(svc)"
							>
								<span class="rel-label">{{svc.name}}</span>
								<span @click.stop="beginDeleteGroup(svc.id)" class="mdi mdi-close rem" title="Delete this saved group"></span>
							</div>
						</div>
					</template>
					<template v-slot:rightside>
						<div style="min-height: 100%;" @click.stop="activeGroupId = null">
							<div class="addNewFolderHold">
								<span class="primary-button" @click="addNewEmptyGroup" style="flex-shrink: 0;">
									<i class="mdi mdi-folder" style="margin-right: 5px;"></i>
									<span>New Folder</span>
								</span>
								<span style="font-size: 11px;">
									Click a survey group below to add stations to it.  The active survey group will be highlighted in green.
								</span>
							</div>
							<div class="addNewFolderHold" v-if="store.reportType === 'reachFrequency'">
								<span>Select a relative survey like 'Latest', otherwise one survey per market</span>
							</div>
							<draggable v-model="mylist" item-key="id" v-bind="dragOptions">
								<template #item="{element, index}">
									<SurveyGroup :data-index="index" :key="element.id" :grpin="element"
											:isactive="isActiveGroup(element.id)"
											v-on:delete="deleteGroup(index)"
											v-on:change="updateGroupSurveys($event, index)"
											v-on:activegroup="activeGroupId = $event"
									></SurveyGroup>
								</template>
							</draggable>
						</div>
					</template>
				</ListsChooser>

			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import SurveyGroup from "@/components/SurveyGroup";
import TopTabBar from "@/components/TopTabBar.vue";
import ListsChooser from "@/components/views/ListsChooser.vue";
import InteriorTab from "@/components/InteriorTab.vue";
import SurveysMarketGroup from "@/components/SurveysMarketGroup.vue";
import draggable from "vuedraggable";
import axios from "axios";

export default {
	name: "SurveysView",
	components: {
		SurveysMarketGroup,
		InteriorTab,
		ListsChooser,
		TopTabBar,
		SurveyGroup,
		draggable,
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			popoverIdShown: null,
			groupview: 'surveys', //relative, relmetro, reldate

			activeGroupId: null,
			filterText: '',
			hiddenMarketIds: [],
		}
	},
	computed: {
		allSurveysActive() {
			let arr = []
			for(let sg of this.store.selectionObs.surveys) {
				if(sg.survobs && sg.survobs.length > 0) {
					for (let survob of sg.survobs) {
						if (!arr.includes(survob)) {
							arr.push(survob)
						}
					}
				}
			}
			return arr
		},
		dragOptions() {
			return {
				animation: 200,
				disabled: false,
				ghostClass: "ghost-drag"
			}
		},
		mylist: {
			get() {
				return this.store.selectionObs.surveys;
			},
			set(value) {
				this.store.selectionObs.surveys = value;
			}
		},
		reportType() {
			return this.store.reportType;
		},
		filteredSurveys() {
			let mysurvs =  this.store.surveys.filter(item => this.store.selectionObs.markets.some(elem => elem.id === item.mktid));
			if(this.filterText.length === 0) return mysurvs
			else {
				return mysurvs.filter(item => this.store.selectionObs.surveys.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		largestGroupIndex() {
			let largestId = 0
			for(let ob of this.store.selectionObs.surveys) {
				let intid = parseInt(ob.id.replace('gr_',''))
				if(intid > largestId) largestId = intid
			}
			return largestId
		},
		filteredSurveyCombos() {
			let mysurvcombos = this.store.surveycombos.filter(item => item.userid === this.store.user.id);
			if(this.filterText.length === 0) return mysurvcombos
			else {
				return mysurvcombos.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
	},
	methods: {
		isActiveGroup(groupid) {
			if(groupid === this.activeGroupId) return true
			return false
		},
		toggleviewed(mktid) {
			if(this.hiddenMarketIds.includes(mktid)) {
				this.hiddenMarketIds = this.hiddenMarketIds.filter(item => item !== mktid);
			}
			else {
				this.hiddenMarketIds.push(mktid);
			}
		},
		addWithNewGroup(svob) {
			let nextid = this.largestGroupIndex + 1
			let tempGroupId = 'gr_'+nextid
			let existingSingleSurveyGroups = this.store.selectionObs.surveys.filter(item => item.survobs.length === 1)
			for(let sg of existingSingleSurveyGroups) {
				if(sg.survobs[0].id === svob.id) {
					return
				}
			}
			this.store.selectionObs.surveys.push({id: tempGroupId, name: svob.name, survobs: [svob], customName: false, isFolder: false})
		},
		addNewEmptyGroup() {
			let nextid = this.largestGroupIndex + 1
			let tempGroupId = 'gr_'+nextid
			this.store.selectionObs.surveys.push({id: tempGroupId, name: 'Survey Group '+nextid, survobs: [], customName: false, isFolder: true})
			setTimeout(function() {
				this.activeGroupId = tempGroupId
			}.bind(this), 50)
		},
		addSurveyToCorrectGroup(svob) {
			if(this.activeGroupId) {
				let gr = this.mylist.find(item => item.id === this.activeGroupId)
				if(gr && !gr.survobs.find(item => item.id === svob.id)) {
					gr.survobs.push(svob)
				}
			}
			else {
				this.addWithNewGroup(svob)
			}
		},
		addSavedGroupToList(svob) {
			if(!this.store.selectionObs.surveys.find(item => item.id === svob.id)) {
				this.store.selectionObs.surveys.push(svob)
			}
		},
		addSavedGroupToListDrag(svob) {
			if(window.mouseIsDown === 1) {
				this.addSavedGroupToList(svob)
			}
		},
		beginDeleteGroup(sgid) {
			if(confirm('Are you sure you want to delete this saved group?')) {
				let self = this
				axios.post(
					"/post/surveygroup-delete.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
						id: sgid, //"sc_1" etc
					})
				).then(function (response) {
					let ret = response.data; //response.data is returned info
					if (ret.resp) { //handle returned objects for lists
						self.showKalert(ret.resp)
					}
					if (ret.surveycombos) self.prepareSurveyCombos(ret.surveycombos)
				}).catch(function (error) {
					console.log(error);
					self.store.showLoader = false;
				});
			}
		},
		selectionToggleDrag(elem) {
			if(window.mouseIsDown === 1) {
				this.addSurveyToCorrectGroup(elem)
			}
		},
		popoverClicked(tid) {
			this.popoverIdShown = tid
		},
		hidePopovers() {
			this.popoverIdShown = null
		},
		checkSurveyAccessThenToggle(ev) {
			let svob = ev.svob
			let maxSurveysAllowed = 999
			if(!['normal','reachFrequency','stationP1'].includes(this.store.reportType)) {
				maxSurveysAllowed = 1
				if(this.allSurveysActive.length >= maxSurveysAllowed) {
					this.showTempDangerAlert("This report type can only be run on one survey at a time")
				}
			}
			if(svob.userCanViewFull === true && this.allSurveysActive.length < maxSurveysAllowed) {
				this.addSurveyToCorrectGroup(svob)
			}
		},
		deleteGroup(ind) {
			this.mylist.splice(ind, 1)
		},
		updateGroupSurveys(obj, index) {
			this.mylist[index] = obj
		},
	},
	watch: {
		// 'store.selectionObs.surveys': {
		// 	deep: true,
		// 	handler() {
		//
		// 	}
		// },
	},
	mounted() {
		if(this.store.reportType === 'reachFrequency' && this.store.selectionObs.surveys.length === 0) {
			this.groupview = 'relative'
			this.addSurveyToCorrectGroup(store.relativeLatestSurveyObs[0])
		}
	},
}
</script>

<style scoped>
.view-holder {
	margin: 20px 20px;
	padding: 0;
}
.surveyIsChosen {
	font-weight: bold;
}
.rel-label {
	padding: 3px 0;
	cursor: pointer;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}
.rel-label:hover {
	text-decoration: underline;
}
.addNewFolderHold {
	padding: 5px;
	border-bottom: 1px solid var(--greymid);
	display: flex;
	gap: 20px;
	align-items: center;
}
.grouphead {
	font-size: 16px;
	margin: 20px 0 10px;
}
.rem {
	cursor: pointer;
	font-size: 14px;
}
.rem:hover {
	color: var(--rederror)
}
.sgroup {
	display: flex;
	justify-content: space-between;
}

</style>