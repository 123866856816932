<template>
	<div class="loader-overlay">
		<div class="popup-container">
			<div class="popup-topbar">
				<span class="bold">Custom Combo Editor</span>
				<span class="closer" @click="store.comboEditOverlayShown = false">
					<i class="mdi mdi-close"></i>
				</span>
			</div>

			<div class="popup-insides" style="text-align: left;">
				<div class="combo-popup-insides">
					<div>
						<p style="font-weight: bold; margin-bottom: 5px;">Combo Name </p>
						<input type="text" v-model="editComboName" style="width: 300px; padding: 5px;"/>
					</div>
					<div v-if="store.user.id === 1" style="">
						<p style="font-weight: bold; margin-bottom: 5px;">Owner </p>
						<select v-model="chosenOwnerId" class="selectbox">
							<option value="1">AL User</option>
							<option value="0">All/Global</option>
						</select>
					</div>
					<div v-if="store.sitesAdmin && store.sitesAdmin.length > 0">
						<p style="font-weight: bold; margin-bottom: 5px;">Site </p>
						<select v-model="siteAccessId" class="selectbox">
							<option :value="0">Just Me</option>
							<option v-for="site in store.sitesAdmin" :key="site.id" :value="site.id">{{site.name}}</option>
						</select>
					</div>
					<div style="">
						<p style="font-weight: bold; margin-bottom: 5px;">Market </p>
						<select v-model="chosenMarketId" class="selectbox">
							<option v-if="store.selectionObs.markets.length > 0" value="sel">Selected Markets</option>
							<option value="0">All Markets</option>
							<option v-for="m in store.markets" :key="m.id" :value="m.id">{{m.name}} {{m.state}}</option>
						</select>
					</div>
				</div>
				<div v-if="editComboQstring.length === 0" class="combo-main-section">
					<div class="combo-left" style="padding: 10px 0; width: 49%; overflow-y: auto;">
						<div style="margin-bottom: 10px;">
							<input type="text" v-model="filterText" style="width: 300px; padding: 5px;" placeholder="Search" />
						</div>
						<div v-for="m in availMarkets" :key="m.id">
							<p @click="toggleExpandedMarket(m.id)" class="mktname">{{m.name}} {{m.state}}
								<span v-if="expandedMktIds.includes(m.id)"><i class="mdi mdi-minus"></i></span>
								<span v-else><i class="mdi mdi-plus"></i></span>
							</p>
							<p v-for="s in filterStationsByText(store.stations.filter(item => item.mktid === m.id))" :key="s.id"
								class="stnname" @click="addStationToCombo(s.id)"
								:class="{active : editStationIds.includes(s.id), hidden : !expandedMktIds.includes(m.id)}"
							>
								<span class="label"
										:class="{inactiveStation : !s.isActive || s.isActive === false}"
										:title="getStationNameLabel(s)"
								>
									{{s.name}}
									<span v-if="s.netgroupown" class="nettext"> {{s.netgroupown.toUpperCase()}}</span>
									<span v-if="s.band && s.band.toLowerCase().includes('dab')" class="dabtext"> ({{s.band.toUpperCase()}})</span>
								</span>
							</p>
						</div>
					</div>
					<div class="combo-right" style="padding: 10px 0; width: 49%; overflow-y: auto;">
						<p v-if="editComboStnObs.length > 0" @click="editComboStnObs = []" style="font-size: 10px; cursor: pointer; margin-bottom: 10px;">Clear All</p>
						<div v-for="stn in editComboStnObs" :key="stn.id" class="stn-item">
							<div>{{stn.name}} ({{getMarketFromStation(stn)}})</div>
							<div class="remover" @click="removeStationFromCombo(stn.id)"><i class="mdi mdi-close"></i>
							</div>
						</div>
					</div>
				</div>
				<div v-if="editComboQstring.length === 0" style="padding: 20px 0;">
					<span @click="saveCombo" class="primary-button" style="margin-right: 10px;">Save</span>
					<span @click="store.comboEditOverlayShown = false" class="primary-button cancel-button">Cancel</span>
				</div>
				<div v-if="editComboQstring.length > 0" style="padding: 20px 0;">
					<p>This combo cannot be edited as it uses a system query dynamically generated station list.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	name: "ComboEdit",
	components: {

	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,

			editComboName: '',
			editComboStnObs: [],
			editComboQstring: '',
			expandedMktIds: [],
			chosenOwnerId: null,
			siteAccessId: 0,
			chosenMarketId: 'sel',
			filterText: '',
		}
	},
	computed: {
		sourceCombob() {
			if (this.store.editComboId === 0) {
				return {
					cid: 0,
					name: '',
					userid: this.store.user.id,
					infolder: 0,
					stationIDsArr: [],
					mktid: 0,
					qstring: '',
					siteAccessId: 0,
				}
			}
			return store.stationcombos.find(item => item.id === this.store.editComboId)
		},
		editStationIds() {
			let c = [];
			for(let i=0; i<this.editComboStnObs.length; i++) {
				c.push(this.editComboStnObs[i].id);
			}
			return c;
		},
		availMarkets() {
			if(this.chosenMarketId === 'sel') return this.store.selectionObs.markets
			else if(this.chosenMarketId > 0) return this.store.markets.filter(item => item.id === this.chosenMarketId);
			let mkts = []
			for(let gid of this.store.gfkMktIds) { //put in order of GFK first, then the rest
				if(this.store.markets.find(item => item.id === gid)) mkts.push(this.store.markets.find(item => item.id === gid))
			}
			for(let row of this.store.markets) {
				if(!this.store.gfkMktIds.includes(row.id)) mkts.push(row)
			}
			return mkts
		},
	},
	methods: {
		filterStationsByText(instations) {
			if(this.filterText.length === 0) return instations
			return instations.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()))
		},
		saveCombo() {
			if(this.editComboName.length === 0) {
				alert('Please enter a name for your combo');
				return false;
			}
			if(this.editComboStnObs.length === 0) {
				alert('Please choose some stations');
				return false;
			}
			let self = this;
			self.store.showLoader = true;
			axios.post(
				"/post/stationcombo-save.php",
				JSON.stringify({
					usercreds: self.store.usercreds,
					cid: self.store.editComboId,
					ownerid: self.chosenOwnerId,
					comboname: self.editComboName,
					mktid: self.chosenMarketId,
					stnids: self.editStationIds.join('|'),
					siteAccessId: self.siteAccessId,
				})
			).then(function (response) {
				let ret = response.data //response.data is returned info
				if (ret.resp) { //handle returned objects for lists
					self.showKalert(ret.resp)
				}
				if(ret.stationcombos) {
					self.store.stationcombos = ret.stationcombos; //update the combos list with the returned items
				}
				if(ret.newComboId) {
					if(self.store.addToStationListAfterComboEdit === true) {
						let mycombo = self.store.stationcombos.find(item => item.cid === ret.newComboId)
						if (mycombo) {
							self.toggleStationInSelection(mycombo)
						}
						self.store.addToStationListAfterComboEdit = false
					}
				}
				self.store.comboEditOverlayShown = false
				self.store.showLoader = false;
			}).catch(function (error) {
				console.log(error);
				self.store.showLoader = false;
			});
		},
		addStationToCombo(stid) {
			if(!this.editStationIds.includes(stid)) { //add it to the combo
				this.editComboStnObs.push(this.store.stations.find(item => item.id === stid));
			}
			else { //remove it
				this.editComboStnObs = this.editComboStnObs.filter(item => item.id !== stid);
			}
		},
		removeStationFromCombo(stid) {
			this.editComboStnObs = this.editComboStnObs.filter(item => item.id !== stid);
		},
		toggleExpandedMarket(mid) {
			if(this.expandedMktIds.includes(mid)) {
				let x = this.expandedMktIds.filter(item => item !== mid);
				this.expandedMktIds = x;
			}
			else {
				this.expandedMktIds.push(mid);
			}
		},
		getMarketFromStation(st) {
			let mkt = this.store.markets.find(item => item.id === st.mktid);
			if(mkt) return mkt.name + " " + mkt.state;
			return '';
		},
		expandSelectedMarkets() {
			if(this.chosenMarketId === 'sel') {
				for(let m of this.store.selectionObs.markets) {
					if(!this.expandedMktIds.includes(m.id)) this.expandedMktIds.push(m.id)
				}
			}
		},
	},
	watch: {
		chosenMarketId() {
			this.expandSelectedMarkets()
		}
	},
	mounted() {
		if(this.sourceCombob.mktid > 0) this.chosenMarketId = this.sourceCombob.mktid
		else if(this.store.selectionObs.markets.length === 0) this.chosenMarketId = 0
		else if(this.store.selectionObs.markets.length === 1) {
			if(!this.expandedMktIds.includes(this.store.selectionObs.markets[0].id)) {
				this.expandedMktIds.push(this.store.selectionObs.markets[0].id)
			}
		}
		else if(this.chosenMarketId === 'sel') {
			this.expandSelectedMarkets()
		}
		this.editComboName = this.sourceCombob.name
		this.editComboStnObs = []
		for(let stid of this.sourceCombob.stationIDsArr) {
			let s = this.store.stations.find(item => stid === item.id)
			if(s) this.editComboStnObs.push(s)
		}
		this.editComboQstring = this.sourceCombob.qstring
		this.chosenOwnerId = this.sourceCombob.userid
		this.siteAccessId = this.sourceCombob.siteAccessId
	}
}
</script>

<style scoped>
.mktname {
	font-size: 13px;
	font-weight: bold;
	cursor: pointer;
	margin-bottom: 5px;
	margin-top: 10px;
}
.stnname {
	cursor: pointer;
	font-size: 13px;
	margin-left: 15px;
}
.stnname.active {
	color: var(--bluemain);
}
.stnname.hidden {
	display: none;
}
.stn-item {
	padding: 4px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #E5E7E9;
	border-radius: 2px;
	background-color: var(--greylight);
	transition: background 0.3s linear;
	margin-bottom: 5px;
}
.combo-popup-insides {
	display: flex;
	justify-content: space-between;
	padding: 0 0 20px 0;
	border-bottom: 1px solid var(--greymid);
}
.combo-main-section {
	height: calc(80% - 0px);
	overflow-y: hidden;
	display: flex;
	justify-content: space-between;
	padding: 0 0 20px 0;
	border-bottom: 1px solid var(--greymid);
}

</style>