<template>
	<div class="rtblHolder">
<!--		<div style="margin-bottom: 20px;">-->
<!--			<p v-for="header in headers" :key="header" v-html="header"></p>-->
<!--		</div>-->

		<table class="excel-table-info">
			<tbody>
			<tr v-for="(header, ind) in headers" :key="ind">
				<td data-f-bold="true" data-f-name="Tahoma" data-f-sz="10" class="boldme" v-html="header"></td>
			</tr>
			<tr><td></td><td></td></tr>
			</tbody>
		</table>

		<table class="std-tbl">
			<thead>
				<tr>
					<td @click="resetSort"></td>
					<td v-for="(ci, index) in columnItems" :key="index" v-html="ci"
						data-f-bold="true" data-fill-color="FFEAEAEA" style="cursor: default;" @click="sortColumn(index+1)"
						data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10"
					></td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(ri, index) in rowItemsSorted" :key="index">
					<td v-for="(rii, index) in ri" :key="index"
						:data-t="getExcelDataType(rii)"
						data-b-a-c="FF888888" data-b-a-s="thin" data-f-name="Tahoma" data-f-sz="10"
					>{{numdisplayKeepExisting(rii)}}</td>
				</tr>
			</tbody>
		</table>

		<ColourHighlightToggler></ColourHighlightToggler>

	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ColourHighlightToggler from "@/components/result-tables/ColourHighlightToggler";
export default {
	name: "ResultTableBasic",
	components: {
		ColourHighlightToggler

	},
	mixins: [globalMixin],
	props: {
		objin: Object,
	},
	data: function () {
		return {
			store: store,
			showAllRowsTogglerRf: true,

			sortColIndex: null,
			sortColOrder: 'desc',
		}
	},
	computed: {
		columnItems() {
			return this.objin.colnames
		},
		rowItems() {
			return this.objin.rows;
		},
		rowItemsSorted() {
			if(this.sortColIndex === null) return this.rowItems
			let sorted = this.rowItems.slice().sort((a, b) => {
				let aval = a[this.sortColIndex]
				let bval = b[this.sortColIndex]
				if(this.sortColOrder === 'asc') {
					if(aval < bval) return -1
					if(aval > bval) return 1
					return 0
				}
				else {
					if(aval > bval) return -1
					if(aval < bval) return 1
					return 0
				}
			})
			return sorted
		},
		headers() {
			return this.objin.header;
		},
		returnedData() {
			return this.store.returnedData;
		},
	},
	methods: {
		resetSort() {
			this.sortColIndex = null
			this.sortColOrder = 'desc'
		},
		sortColumn(colindex) {
			if(this.sortColIndex === colindex) { //reverse the already active sort
				if(this.sortColOrder === 'asc') this.sortColOrder = 'desc'
				else this.sortColOrder = 'asc'
			}
			else this.sortColIndex = colindex //either no column yet chosen or a different column, sort by that col
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.std-tbl tbody tr:last-of-type.notshown {
	display: table-row;
}
</style>