<template>
	<div>
<!--		<div style="padding-bottom: 10px;">-->
<!--			<p style="margin-top: 5px; font-size:12px; color: #888">Total Spots is the sum of all spots on all stations across the schedule. Spot numbers in the sessions below are assumed to be spread across the week (eg 10 spots in Morning means 10 spots total placed in mornings in that station across the week eg 2 per day). All dayparts are Mon-Fri except Weekend.</p>-->
<!--		</div>-->
		<div class="">
			<p class="subheading" style="margin-top: 15px; margin-bottom: 5px;">
				<span style="margin-right: 20px;">
					Weeks to show:
					<select v-if="campaignLines[0]" v-model="weeksToShow" class="selectbox">
						<option v-for="w in campaignLines[0].spotsByWeek" :key="w.weekNumber" :value="w.weekNumber">{{w.weekNumber}}</option>
					</select>
				</span>
				<br/>
				<input id="rander" type="checkbox" v-model="randomiseSpotsWithinCombos" />
				<label for="rander" style="font-weight: normal; margin-left: 5px;"
					title="Tick this to randomise generated spot times within combos.  If left unticked, spots generated for combos will have same play time for each station"
				>Randomise spot times within combos</label>
				<span @click="switchToNewPreCampaignView" class="text-link" style="font-style: normal; font-weight:normal; font-size: 12px; margin-left: 10px;">Switch to New View</span>
			</p>
			<p style="font-size: 12px; font-style: italic; margin-bottom: 10px;">
				Radio360 Radio Types "AM/FM/DAB" and "Streaming" can only be used for GFK metro surveys from S3 2023 onwards.
				All GFK provincial, Xtra, and metro surveys prior to S3 2023 must only use Radio Type = "Total Radio" to return results.
			</p>
		</div>
		<div style="width: 100%; overflow-x: auto; margin: 20px 0 10px;" ref="scrollTable">
			<table class="std-tbl rfschedtbl">
				<thead>
					<tr>
						<td></td>
						<td>Station / Combo</td>
						<td>Daypart</td>
						<td>Radio Type</td>
						<td>Cost per Spot</td>
						<td>Spot Type</td>
						<td v-for="w in weeksToShow" :key="w" style="text-align:center;">Wk {{w}}<br/>{{getDateByWeekId(w, true)}}</td>
						<td style="text-align:center;">Row Total</td>
						<td style="min-width: 60px;"></td>
					</tr>
				</thead>
				<draggable tag="tbody" item-key="station" v-model="campaignLines" handle=".handle">
					<template #item="{element, index}">
						<tr>
							<td>
								<i class="mdi mdi-drag handle"></i>
								<div class="left-row-overlay" :class="{scrolling : tableScrollPositionX > 120}" :style="'left:'+tableScrollPositionX+'px'">
									<span>{{getStationNameFromId(element.stationId)}}, {{getDaypartNameFromId(element.daypart)}}, {{getAudioTypeNameFromId(element.audioType)}}</span>
								</div>
							</td>
							<td><RFStationChooser :allowcombos="true" :inval="element.stationId" v-on:update="element.stationId = $event"></RFStationChooser></td>
							<td><RFDaypartChooser :useselecteddayparts="true" :inval="element.daypart" v-on:update="element.daypart = $event"></RFDaypartChooser></td>
							<td><RFAudioTypeChooser :inval="element.audioType" v-on:update="element.audioType = $event" :station="element.stationId"></RFAudioTypeChooser></td>
							<td><input type="number" class="rfinputfield num" v-model="element.spotRate" /></td>
							<td><input type="text" class="rfinputfield" v-model="element.spotClass" style="width: 100px;" /></td>
							<td v-for="wk in weeksToShow" :key="wk">
								<input type="number" class="rfinputfield num numwk" v-model="element.spotsByWeek[wk-1].nSpots" @focus="$event.target.select()" />
							</td>
							<td>{{getRowTotal(element)}}</td>
							<td>
								<i class="mdi mdi-content-copy copyched" title="Clone row to end of table" @click="cloneRow(index)"></i>
								<i class="mdi mdi-close remsched" title="Delete row" @click="deleteRowFromCampaign(index)"></i>
							</td>
						</tr>
					</template>
				</draggable>
			</table>
		</div>
		<div style="margin: 15px 0; display: flex; gap: 10px;">
			<div class="primary-button" @click="addBlankRowToCampaign">
				<i class="mdi mdi-plus"></i>
				Add Campaign Row
			</div>
			<div class="primary-button remove-button" @click="clearCampaign">
				<i class="mdi mdi-close"></i>
				Clear
			</div>
			<div class="primary-button" @click="generateSpotsList(weekstartson, false, activedate)">
				<i class="mdi mdi-play"></i>
				Generate Spots
			</div>
			<div class="primary-button" @click="exportShellPre()">
				<i class="mdi mdi-export"></i>
				Export XLS Template
			</div>
			<div class="primary-button" @click="importShell()">
				<i class="mdi mdi-import"></i>
				Import XLS Template
			</div>
		</div>
		<PreSchedExportPrefs v-if="showShellExportSettingsPopup" @close="showShellExportSettingsPopup = false"
			@doexport="exportShell($event)"
		></PreSchedExportPrefs>
		<PreSchedShellUploader v-if="showShellImporter" @close="showShellImporter = false"
			@shellimport="handleShellImport($event)"
		></PreSchedShellUploader>
		<div v-if="weekstartson !== 'sun'" style="margin: 15px 0 0; display: flex; gap: 10px; max-width: 1000px;">
			<p style="color: var(--bluedark);">Please note that radio R&F calculations must be run on Sun-Sat weeks as per the CRA Gold Standard.
				The "Week Starts On" day selected will generate spots within weeks starting on your selected day,
				however the generated spots list below will count spots within Sun-Sat weeks, and calculations will be done Sun-Sat.
			</p>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import RFStationChooser from "@/components/rf/RFStationChooser";
import RFAudioTypeChooser from "@/components/rf/RFAudioTypeChooser";
import RFDaypartChooser from "@/components/rf/RFDaypartChooser";
import draggable from "vuedraggable";
import Exceljs from "exceljs";
import PreSchedShellUploader from "@/components/rf/PreSchedShellUploader.vue";
import PreSchedExportPrefs from "@/components/rf/PreSchedExportPrefs.vue";

export default {
	name: "RFPreCampaignEditor",
	components: {
		PreSchedExportPrefs,
		PreSchedShellUploader,
		RFDaypartChooser,
		RFAudioTypeChooser,
		RFStationChooser,
		draggable
	},
	props: {
		weekstartson: {
			type: String,
			default: 'sun'
		},
		activedate: {
			type: String,
			default: ''
		},
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			tableScrollPositionX: 0,
			showShellImporter: false,
			showShellExportSettingsPopup: false,
		}
	},
	computed: {
		activeDatesByWeekId() {
			if(this.activedate === '') return []
			//get day of week for activedate
			let diffs = [
				{day: 'sun', diff: 0},
				{day: 'mon', diff: 1},
				{day: 'tue', diff: 2},
				{day: 'wed', diff: 3},
				{day: 'thu', diff: 4},
				{day: 'fri', diff: 5},
				{day: 'sat', diff: 6},
			]
			let d = new Date(this.activedate)
			let dayId = d.getDay()
			let dayDiff = dayId - diffs.find(item => item.day === this.weekstartson).diff
			if(dayDiff < 0) dayDiff = 7 + dayDiff
			let start = new Date(d)
			start.setDate(d.getDate() - dayDiff)
			let res = []
			//get start as YYYY-MM-DD
			let startstr = start.toISOString().split('T')[0]
			res.push(startstr)
			for(let i=1; i<52; i++) { //add 7 days to start
				let newdate = new Date(start)
				newdate.setDate(start.getDate() + 7)
				res.push(newdate.toISOString().split('T')[0])
				start = newdate
			}
			return res
		},
		randomiseSpotsWithinCombos: {
			get() {
				return this.store.rfConfig.randomiseSpotTimesWithinCombos
			},
			set(newval) {
				this.store.rfConfig.randomiseSpotTimesWithinCombos = newval
			}
		},
		campaignLines: {
			get() {
				return this.store.rfConfig.campaignLines
			},
			set(newob) {
				this.store.rfConfig.campaignLines = newob
			}
		},
		weeksToShow: {
			get() {
				return this.store.precampaignWeeksShown
			},
			set(newint) {
				this.store.precampaignWeeksShown = newint
			}
		},
	},
	methods: {
		exportShellPre() {
			this.showShellExportSettingsPopup = true
		},
		exportShell(settings) { //settings is an object with allradiotypes, onlycreatedrows, includeconfig
			const workbook = new Exceljs.Workbook()
			let worksheet = workbook.addWorksheet('Sheet 1')
			let worksheetData = []
			if(settings.includeconfig === true) {
				let c = {
					marketidArr: this.store.selectionObs.markets.map(m => m.id),
					surveySets: this.prepareSurveySetForSend(true),
					stationidArr: this.store.selectionObs.stations.map(s => s.id),
					demoidArr: this.getObIdsToArr(this.store.selectionObs.demographics),
					daypartsidArr: this.getObIdsToArr(this.store.selectionObs.dayparts),
				}
				worksheetData.push([{t: ''},{t: JSON.stringify(c)}]) //JSON in A2
			}
			else worksheetData.push([])
			//headings row
			let worksheetHeaderRow = [
				{t: 'StationId', s: this.store.xlfmt.boldborder},
				{t: 'DaypartId', s: this.store.xlfmt.boldborder},
				{t: 'RTID', s: this.store.xlfmt.boldborder},
				{t: 'Station / Combo', s: this.store.xlfmt.boldborder},
				{t: 'Daypart', s: this.store.xlfmt.boldborder},
				{t: 'Radio Type', s: this.store.xlfmt.boldborder},
				{t: 'Cost per Spot', s: this.store.xlfmt.boldborder},
				{t: 'Spot Type', s: this.store.xlfmt.boldborder},
			]
			for(let i=0; i<52; i++) {
				let wklabel = 'Week '+(i+1) // + '\r\n'+this.getDateByWeekId(i+1, true)
				worksheetHeaderRow.push({t: wklabel, s: this.store.xlfmt.boldborder})
			}
			worksheetData.push(worksheetHeaderRow)
			//second row with dates
			let datesrow = [
				{t: '', s: this.store.xlfmt.boldborder},
				{t: '', s: this.store.xlfmt.boldborder},
				{t: '', s: this.store.xlfmt.boldborder},
				{t: '', s: this.store.xlfmt.boldborder},
				{t: '', s: this.store.xlfmt.boldborder},
				{t: '', s: this.store.xlfmt.boldborder},
				{t: '', s: this.store.xlfmt.boldborder},
				{t: '', s: this.store.xlfmt.boldborder},
			]
			for(let i=0; i<52; i++) {
				let wklabel = this.getDateByWeekId(i+1, false)
				datesrow.push({t: wklabel, s: this.store.xlfmt.boldborder})
			}
			worksheetData.push(datesrow)
			let myradioTypeIds = []
			if(settings.includeTotalRadio === true) myradioTypeIds.push(0)
			if(settings.includeAMFMDAB === true) myradioTypeIds.push(1)
			if(settings.includeStreaming === true) myradioTypeIds.push(2)
			if(myradioTypeIds.length === 0) myradioTypeIds = [0]
			if(settings.onlycreatedrows === true) { //just the rows we already see in the UI
				for(let cl of this.campaignLines) {
					let stname = this.getStationNameFromId(cl.stationId)
					let dp = this.store.selectionObs.dayparts.find(d => d.id === cl.daypart)
					let dpname = ''
					if(datesrow) dpname = dp.name
					let at = this.store.audioTypes.find(a => a.id === cl.audioType)
					let atname = ''
					if(at) atname = at.name
					let newrow = [
						{t: cl.stationId, s: this.store.xlfmt.textborder},
						{t: cl.daypart, s: this.store.xlfmt.textborder},
						{t: cl.audioType, s: this.store.xlfmt.textborder},
						{t: stname, s: this.store.xlfmt.textborder},
						{t: dpname, s: this.store.xlfmt.textborder},
						{t: atname, s: this.store.xlfmt.textborder},
						{t: cl.spotRate, s: this.store.xlfmt.textborder},
						{t: cl.spotClass, s: this.store.xlfmt.textborder},
					]
					for (let i = 0; i < 52; i++) {
						let spw = ''
						let sw = cl.spotsByWeek.find(s => s.weekNumber === i+1)
						if(sw) spw = sw.nSpots
						newrow.push({t: spw, s: this.store.xlfmt.textborder})
					}
					worksheetData.push(newrow)
				}
			}
			else { //all permutations of station, daypart, radiotype
				for (let st of this.store.selectionObs.stations) {
					let stname = st.name
					if (st.isCombo === true) stname = '(c) ' + stname
					if (st.outsidemkt === true) stname += ' (' + this.getMarketNameFromId(st.mktid) + ')'
					for (let dp of this.store.selectionObs.dayparts) {
						for (let atid of myradioTypeIds) {
							if (atid === 0 || st.mktid === 0 || this.store.gfkMetroMktIds.includes(st.mktid)) { //only total radio for GFK metro
								let at = this.store.audioTypes.find(a => a.id === atid)
								let newrow = [
									{t: st.id, s: this.store.xlfmt.textborder},
									{t: dp.id, s: this.store.xlfmt.textborder},
									{t: at.id, s: this.store.xlfmt.textborder},
									{t: stname, s: this.store.xlfmt.textborder},
									{t: dp.name, s: this.store.xlfmt.textborder},
									{t: at.name, s: this.store.xlfmt.textborder},
									{t: '', s: this.store.xlfmt.textborder},
									{t: '', s: this.store.xlfmt.textborder},
								]
								for (let i = 0; i < 52; i++) {
									newrow.push({t: '', s: this.store.xlfmt.textborder})
								}
								worksheetData.push(newrow)
							}
						}
					}
				}
			}
			//write to the sheet
			for(let rowInt=1; rowInt<=worksheetData.length; rowInt++) {
				for(let colInt=1; colInt<=worksheetData[rowInt-1].length; colInt++) {
					worksheet.getCell(rowInt, colInt).value = worksheetData[rowInt-1][colInt-1].t
					if(worksheetData[rowInt-1][colInt-1].s) {
						if(worksheetData[rowInt-1][colInt-1].s.font) {
							worksheet.getCell(rowInt, colInt).font = worksheetData[rowInt-1][colInt-1].s.font
						}
						else {
							worksheet.getCell(rowInt, colInt).font = {name: 'Tahoma', size: 10}
						}
						if(worksheetData[rowInt-1][colInt-1].s.fill) {
							worksheet.getCell(rowInt, colInt).fill = worksheetData[rowInt-1][colInt-1].s.fill
						}
						if(worksheetData[rowInt-1][colInt-1].s.border) {
							worksheet.getCell(rowInt, colInt).border = worksheetData[rowInt-1][colInt-1].s.border
						}
						if(worksheetData[rowInt-1][colInt-1].s.numFmt) {
							worksheet.getCell(rowInt, colInt).numFmt = worksheetData[rowInt-1][colInt-1].s.numFmt
						}
					}
					else {
						worksheet.getCell(rowInt, colInt).font = {name: 'Tahoma', size: 10}
					}
				}
			}
			//hide first three columns
			worksheet.getColumn(1).hidden = true
			worksheet.getColumn(2).hidden = true
			worksheet.getColumn(3).hidden = true
			//hide top row
			worksheet.getRow(1).hidden = true
			//set column widths
			worksheet.getColumn(4).width = 30
			worksheet.getColumn(5).width = 30
			worksheet.getColumn(6).width = 20
			worksheet.getColumn(7).width = 15
			worksheet.getColumn(8).width = 15
			//export file
			workbook.xlsx.writeBuffer().then((data) => {
				const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
				const url = window.URL.createObjectURL(blob)
				const a = document.createElement('a')
				a.href = url
				a.download = 'RF Preschedule Shell.xlsx'
				a.click()
				window.URL.revokeObjectURL(url)
			})
			this.showShellExportSettingsPopup = false
		},
		importShell() {
			let c = confirm('This will clear the current campaign lines and import from Excel shell.  Are you sure?')
			if(c === true) {
				this.showShellImporter = true
			}
		},
		handleShellImport(ob) {
			let rows = ob.rows
			let hasExplicitConfigSelections = false
			if(ob.jsonSelections) {
				hasExplicitConfigSelections = true
				let selections = ob.jsonSelections //if we have explicit json selections to handle
				let surveySets = this.handleIncomingSurveysetsFromJob(selections.surveySets)
				let stationArr = []
				for (let id of selections.stationidArr) {
					let newob = this.store.stations.find(item => item.id === id)
					if (newob) stationArr.push(newob)
					else { //check for combos
						newob = this.store.stationcombos.find(item => item.id === id)
						if (newob) stationArr.push(newob)
					}
				}
				this.store.selectionObs = {
					markets: this.getObjectsFromArrayById(this.store.markets, selections.marketidArr),
					surveys: surveySets,
					stations: stationArr,
					demographics: this.getObjectsFromArrayById(this.store.demographics, selections.demoidArr),
					dayparts: this.getObjectsFromArrayById(this.store.dayparts, selections.daypartsidArr),
					statistics: [],
				}
			}
			if(rows.length > 0) {
				let allStids = []
				let allDps = []
				let clines = []
				for(let r of rows) {
					let newrow = {
						stationId: r.stationId,
						daypart: parseInt(r.daypartId),
						audioType: parseInt(r.audioTypeId),
						spotRate: r.cost,
						ratecardRate: null,
						spotClass: r.spotType,
						spotsByWeek: []
					}
					for(let i=1; i<=52; i++) {
						newrow.spotsByWeek.push({nSpots: parseInt(r['week'+i]), weekNumber: i})
					}
					clines.push(newrow)
					if(!allStids.includes(r.stationId)) allStids.push(r.stationId)
					if(!allDps.includes(r.daypartId)) allDps.push(r.daypartId)
				}
				if(hasExplicitConfigSelections === false) {
					for (let stid of allStids) {
						if (!this.store.selectionObs.stations.find(st => st.id === stid)) {
							let mystn = this.store.stations.find(st => st.id === stid)
							if (mystn) this.store.selectionObs.stations.push(mystn)
							let mycombo = this.store.stationcombos.find(c => c.id === stid)
							if (mycombo) this.store.selectionObs.stations.push(mycombo)
						}
					}
					for (let dp of allDps) {
						if (!this.store.selectionObs.dayparts.find(d => d.id === dp)) {
							let mydp = this.store.dayparts.find(d => d.id === dp)
							if (mydp) this.store.selectionObs.dayparts.push(mydp)
						}
					}
				}
				this.campaignLines = clines
			}
			this.showShellImporter = false
		},
		deleteRowFromCampaign(ind) {
			this.campaignLines.splice(ind, 1)
		},
		cloneRow(ind) {
			let lr = JSON.parse(JSON.stringify(this.campaignLines[ind])) //create a deep copy
			this.store.rfConfig.campaignLines.push(lr)
		},
		addBlankRowToCampaign() {
			this.store.rfConfig.campaignLines.push({
				stationId: null,
				daypart: null,
				audioType: 0,
				spotRate: null,
				ratecardRate: null,
				spotClass: null,
				spotsByWeek: JSON.parse(JSON.stringify(this.store.defaultRfCampaignWeeks))
			})
		},
		clearCampaign() {
			this.store.rfConfig.campaignLines = []
		},
		getDateByWeekId(weekId, nice = false) {
			if(nice === false) return this.activeDatesByWeekId[weekId-1]
			else {
				let arr = this.activeDatesByWeekId[weekId-1].split('-')
				return arr[2] + ' ' + this.niceMonthFromInt(arr[1])
			}
		},
		getRowTotal(rowElement) {
			let total = 0
			for(let i=0; i<rowElement.spotsByWeek.length; i++) {
				let myspots = parseInt(rowElement.spotsByWeek[i].nSpots)
				if(!isNaN(myspots)) total += myspots
			}
			return total
		},
		getStationNameFromId(stid) {
			let stob = this.store.selectionObs.stations.find(st => st.id === stid)
			if(stob) {
				let myname = stob.name
				if(stob.isCombo === true) myname = '(c) '+myname
				if(stob.outsidemkt === true) myname += ' (' + this.getMarketNameFromId(stob.mktid) + ')'
				return myname
			}
			return 'No Station Yet'
		},
		getDaypartNameFromId(dp) {
			let dpob = this.store.selectionObs.dayparts.find(d => d.id === dp)
			if(dpob) return dpob.name
			return 'No Daypart Yet'
		},
		getAudioTypeNameFromId(at) {
			if(at === 0) return 'Total Radio'
			else if(at === 1) return 'AM/FM/DAB+'
			else if(at === 2) return 'Streaming'
			return ''
		},
	},
	watch: {

	},
	mounted() {
		if(!this.campaignLines || this.campaignLines.length === 0) this.addBlankRowToCampaign()
		const scroll = this.$refs.scrollTable
		scroll.addEventListener('scroll', () => {
			this.tableScrollPositionX = this.$refs.scrollTable.scrollLeft
		}, false)
	}
}
</script>

<style scoped>
.left-row-overlay {
	background: #FFF;
	position: absolute;
	z-index: 4;
	height: 36px;
	width: 400px;
	top: 0;
	padding: 8px;
	align-items: center;
	display: none;
}
.left-row-overlay.scrolling {
	display: flex;
}
.rfschedtbl tbody tr:nth-of-type(odd) .left-row-overlay {
	background: #F9F9F9;
}
.rfschedtbl tbody tr {
	position: relative;
}
</style>