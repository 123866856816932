<template>
	<div>
		<select @change="$emit('update', selectval)" v-model="selectval" class="selectbox">
			<option v-for="c in choices" :value="c.k" :key="c.k">{{c.v}}</option>
		</select>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "RFStationChooser",
	components: {},
	props: {
		inval: {
			type: [String, Number, Object]
		},
		allowcombos: {
			type: Boolean,
			default: false
		},
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			selectval: null,
		}
	},
	computed: {
		choices() {
			let arr = []
			for(let st of this.store.selectionObs.stations) {
				if(this.allowcombos === false && st.isCombo === true) continue
				let myname = st.name
				if(st.isCombo === true) myname = '(c) '+myname
				else myname += ' (' + this.getMarketNameFromId(st.mktid) + ')'
				arr.push({k: st.id, v: myname})
			}
			return arr
		}
	},
	methods: {
		updateInternal() {
			this.selectval = this.inval
		}
	},
	watch: {
		inval() {
			this.updateInternal()
		}
	},
	mounted() {
		this.updateInternal()
	}
}
</script>

<style scoped>

</style>