<template>
	<div class="sortable-internal">
		<div :class="{boldme : isStationCombo}">
			<span v-if="isStationCombo" title="Station Combo">
				<i class="mdi mdi-circle-multiple-outline"></i>
			</span>
			{{title}}
		</div>
		<div class="remover" @click="$emit('remove')"><i class="mdi mdi-close"></i></div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "SortableInternal",
	components: {},
	mixins: [globalMixin],
	props: {
		type: String,
		objid: [Number, String],
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		obj() {
			if(!this.type) return false
			if(this.type === 'station') {
				if (this.objid.startsWith('c_')) return this.store.stationcombos.find(item => item.id === this.objid);
				else return this.store.stations.find(item => item.id === this.objid);
			}
			else if(this.type === 'demographic' || this.type === 'demo') {
				return this.store.demographics.find(item => item.id === this.objid);
			}
			else if(this.type === 'daypart') {
				return this.store.dayparts.find(item => item.id === this.objid);
			}
			else if(this.type === 'statistic') {
				return this.store.statistics.find(item => item.id === this.objid);
			}
			else if(this.type === 'survey') {
				return this.store.surveys.find(item => item.id === this.objid);
			}
			return false;
		},
		isStationCombo() {
			if(this.obj.type === 'station' && this.obj.isCombo === true) return true
			return false
		},
		title() {
			if(this.obj && this.obj.name) {
				if (this.type === 'station') {
					let myname = this.obj.name
					if(this.obj.outsidemkt === true) myname += ' (' + this.getMarketNameFromId(this.obj.mktid) + ')'
					return myname
				}
				else if (this.type === 'demographic' || this.type === 'demo') return this.obj.name;
				else if (this.type === 'daypart') return this.obj.name;
				else if (this.type === 'statistic') return this.obj.name;
				else if (this.type === 'survey') return this.obj.name;
			}
			return '';
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.sortable-internal {
	padding: 4px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

</style>