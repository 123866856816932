<template>
	<div>
		<table v-if="store.rfConfig.schedule.length > 0" class="std-tbl rfschedtbl rfschedtbl-condense">
			<thead>
				<tr>
					<td>Station</td>
					<td>Spot Date</td>
					<td>Aired Time</td>
					<td>Spot Duration</td>
					<td>Radio Type</td>
					<td>Cost</td>
					<td>Spot Type</td>
					<td>Key Number</td>
					<td>Campaign</td>
					<td>Booked Daypart</td>
					<td>Ratecard Rate</td>
					<td>Product</td>
					<td></td>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(sp, index) in storeschedActivePaginated" :key="index">
					<td>
						<RFStationChooser v-if="index === editingRowIndex" :inval="sp.stationId" v-on:update="sp.stationId = $event"></RFStationChooser>
						<span v-else>{{getStationNameFromId(sp.stationId)}}</span>
					</td>
					<td>
						<input v-if="index === editingRowIndex" type="text" class="rfinputfield" v-model="sp.playDate" style="width: 100px;" />
						<span v-else>{{sp.playDate}}</span>
					</td>
					<td>
						<input v-if="index === editingRowIndex" type="text" class="rfinputfield" v-model="sp.playTime" style="width: 100px;" />
						<span v-else>{{sp.playTime}}</span>
					</td>
					<td>
						<input v-if="index === editingRowIndex" type="number" class="rfinputfield num" v-model="sp.spotDuration" />
						<span v-else>{{sp.spotDuration}}</span>
					</td>
					<td>
						<RFAudioTypeChooser v-if="index === editingRowIndex" :inval="sp.audioType" v-on:update="sp.audioType = $event" :station="sp.stationId"></RFAudioTypeChooser>
						<span v-else>{{getAudioTypeNameFromId(sp.audioType)}}</span>
					</td>
					<td>
						<input v-if="index === editingRowIndex" type="number" class="rfinputfield num" v-model="sp.spotRate" />
						<span v-else>{{sp.spotRate}}</span>
					</td>
					<td>
						<input v-if="index === editingRowIndex" type="text" class="rfinputfield" v-model="sp.spotClass" />
						<span v-else>{{sp.spotClass}}</span>
					</td>
					<td>
						<input v-if="index === editingRowIndex" type="text" class="rfinputfield" v-model="sp.keyNumber" />
						<span v-else>{{sp.keyNumber}}</span>
					</td>
					<td>
						<input v-if="index === editingRowIndex" type="text" class="rfinputfield" v-model="sp.campaign" />
						<span v-else>{{sp.campaign}}</span>
					</td>
					<td>
						<input v-if="index === editingRowIndex" type="text" class="rfinputfield" v-model="sp.bookedDaypart" />
						<span v-else>{{sp.bookedDaypart}}</span>
					</td>
					<td>
						<input v-if="index === editingRowIndex" type="number" class="rfinputfield num" v-model="sp.ratecardRate" />
						<span v-else>{{sp.ratecardRate}}</span>
					</td>
					<td>
						<input v-if="index === editingRowIndex" type="text" class="rfinputfield" v-model="sp.product" />
						<span v-else>{{sp.product}}</span>
					</td>
					<td class="icnholdrow">
						<i class="mdi mdi-pencil-outline" title="Edit spot" @click="editRow(index)"></i>
						<i class="mdi mdi-content-copy" title="Clone row to end of table" @click="cloneRow(index)"></i>
						<i class="mdi mdi-close" title="Delete row" @click="deleteRowFromSchedule(index)"></i>
					</td>
				</tr>
			</tbody>
		</table>
		<div v-if="maxPages > 1" style="margin: 20px 0;">
			<span style="margin-right: 10px; font-size: 12px;">Page</span>
			<select v-model="activePage">
				<option v-for="n in maxPages" :key="n" :value="n-1">{{n}}</option>
			</select>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";
import RFStationChooser from "@/components/rf/RFStationChooser.vue";
import RFAudioTypeChooser from "@/components/rf/RFAudioTypeChooser.vue";

export default {
	name: "RFPostTimesScheduleEditor",
	components: {RFAudioTypeChooser, RFStationChooser},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			displayRowLimit: 100,
			activePage: 0,
		}
	},
	computed: {
		storesched: {
			get() {
				return this.store.rfConfig.schedule
			},
			set(newob) {
				this.store.rfConfig.schedule = newob
			}
		},
		paginateStart() {
			return this.activePage * this.displayRowLimit
		},
		paginateEnd() {
			return this.paginateStart + this.displayRowLimit
		},
		maxPages() {
			return Math.ceil(this.store.rfConfig.schedule.length / this.displayRowLimit)
		},
		storeschedActivePaginated: {
			get() {
				return this.store.rfConfig.schedule.slice(this.paginateStart, this.paginateEnd)
			},
			set(newob) {
				this.store.rfConfig.schedule = newob
			}
		},
		editingRowIndex: {
			get() {
				return this.store.rfScheduleEditRowIndex
			},
			set(newval) {
				this.store.rfScheduleEditRowIndex = newval
			}
		}
	},
	methods: {
		editRow(ind) {
			if(this.editingRowIndex === ind) this.editingRowIndex = null
			else this.editingRowIndex = ind
		},
		deleteRowFromSchedule(ind) {
			this.store.rfConfig.schedule.splice(ind, 1)
		},
		cloneRow(ind) {
			let lr = JSON.parse(JSON.stringify(this.store.rfConfig.schedule[ind])) //create a deep copy
			this.store.rfConfig.schedule.push(lr)
			this.editingRowIndex = this.store.rfConfig.schedule.length - 1 //mark the newly added row as the one being edited
		},
	},
	watch: {
		storesched() {
			if(this.storesched.length < this.paginateEnd) {
				this.activePage = 0
			}
		},
	},
	mounted() {
		this.editingRowIndex = null
	}
}
</script>

<style scoped>
.rfinputfield {
	width: 100px;
}
</style>