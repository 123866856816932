<template>
	<div class="loader-overlay">
		<div class="popup-container">
			<div class="popup-topbar">
				<span class="bold">Custom Demographic Editor</span>
				<span class="closer" @click="store.demoEditOverlayShown = false">
					<i class="mdi mdi-close"></i>
				</span>
			</div>

			<div class="popup-insides" style="text-align: left;">
				<div v-if="fetching">Loading...</div>
				<div v-else>
					<div>
						<p>Due to the differing survey methodologies, surveys collected by GFK (Syd, Mel, Bne, Adl, Per, Cbr, GC, Newc) have many more available demographic attributes to choose from.
							To create a demographic for national markets (ie combination of GFK & Xtra markets), there is a limited subset of demographic attributes that can be chosen.</p>
						<br/>
						<p>If you know that your demographic will only be used for GFK surveys and you need Lifestyle/Buyergraphic questions, choose <span class="bold">"GFK only surveys"</span> below to see the full range of demographic options.</p>
						<br/>
						<div>
							<input type="radio" v-model="showFor" value="global" id="showglobal" /><label for="showglobal">Both GFK & Xtra Surveys</label>
							<input type="radio" v-model="showFor" value="gfk" id="showgfk" /><label for="showgfk">GFK only surveys</label>
							<input type="radio" v-model="showFor" value="xtra" id="showxtra" /><label for="showxtra">Xtra only surveys</label>
						</div>
						<br/>
						<div v-if="store.user.isAdmin === 1 && store.user.id === 1">
							<select v-model="isGlobalDemo" class="selectbox">
								<option value="true">All Users</option>
								<option value="false">Own User</option>
							</select>
							<select v-model="specificSurveyId" style="margin-left: 20px;" class="selectbox">
								<option value="0">All Surveys</option>
								<option v-for="su in store.surveys" :key="su.survey_code" :value="su.survey_code">{{su.name}}</option>
							</select>
							<div v-if="store.sitesAdmin && store.sitesAdmin.length > 0" style="margin-left: 10px; display: inline-block;">
								<select v-model="siteAccessId" class="selectbox">
									<option :value="0">Just Me</option>
									<option v-for="site in store.sitesAdmin" :key="site.id" :value="site.id">{{site.name}}</option>
								</select>
							</div>
						</div>

						<br/>
					</div>

					<div class="demos-halves-flex">
						<div class="demo-left-side">
							<div>
								<p style="font-weight: bold; margin-bottom: 5px;">Demo Name </p>
								<input type="text" v-model="editDemoName" style="width: 300px; padding: 5px;" />
								<span @click="saveDemo" class="primary-button" style="margin: 0 20px 0 10px; display: inline-block;">Save</span>
								<span @click="clearAll" style="font-size: 10px; margin: 0 10px 0 0; cursor: pointer; color: #28A; text-decoration: underline">Reset</span>
							</div>

							<div class="attributes-list">
								<!-- for specific survey demographics -->
								<div>
									<div v-for="at in specificSurvQuestions" :key="at.demo_att_id">
										<span class="dhead" style="user-select: none;">{{at.desc}}</span><br/>
										<div v-for="(opt, ind) in at.items" :key="ind"
											@mousedown.left="selectionToggleStart(at.demo_att_id+'|'+opt.id)"
											@mouseover="selectionToggleDrag(at.demo_att_id+'|'+opt.id)"
										>
											<SubTreeItem :label="opt.item_desc" :is_selected="selections.includes(at.demo_att_id+'|'+opt.id)"></SubTreeItem>
										</div>
									</div>
								</div>


								<!-- for standard demographics -->
								<div v-if="showFor !== 'gfk'">
									<div v-for="at in displayedAttributes" :key="at.demo_att_id">
										<span class="dhead" style="user-select: none;">{{at.desc}}</span><br/>
										<div v-for="(opt, ind) in at.items" style="user-select: none;" :key="ind"
											@mousedown.left="selectionToggleStart(at.demo_att_id+'|'+opt.id)"
											@mouseover="selectionToggleDrag(at.demo_att_id+'|'+opt.id)"
										>
											<SubTreeItem :label="opt.item_desc" :is_selected="selections.includes(at.demo_att_id+'|'+opt.id)"></SubTreeItem>
										</div>
									</div>
								</div>
								<div v-else>
									<div v-for="dg in store.demographic_groups" :key="dg.id">
										<p style="font-weight: bold; border-top: 1px solid #CCC; padding-top: 10px; margin: 20px 0 0;">
											{{dg.name}}
											<i class="mdi mdi-plus plus-toggle-btn" @click="toggleGroupShow(dg.id)"></i>
										</p>
										<div v-if="openGroups.includes(dg.id)">
											<div v-for="at in displayedAttributes.filter(item => item.group === dg.id)" :key="at.demo_att_id">
												<span class="dhead" style="user-select: none;">{{at.desc}}</span><br/>
												<div v-for="(opt, ind) in at.items" style="user-select: none;" :key="ind"
													@mousedown.left="selectionToggleStart(at.demo_att_id+'|'+opt.id)"
													@mouseover="selectionToggleDrag(at.demo_att_id+'|'+opt.id)"
												>
													<SubTreeItem :label="opt.item_desc" :is_selected="selections.includes(at.demo_att_id+'|'+opt.id)"></SubTreeItem>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
						<div class="demo-right-side">
							<div class="shown-selections">
								<p style="font-weight: bold; margin-bottom: 5px;">Selections</p>
								<div v-for="(selarr, selindex) in selectionsArray" :key="selindex">
									<div class="arraygroup" :class="{activearray : activeSelectionIndex === selindex}">
										<p @click="activeSelectionIndex = selindex" class="addtogroup">
											<span v-if="selindex !== activeSelectionIndex">Add to this selection group</span>
											<span v-else>Active selection group</span>
										</p>
										<div v-for="sel in selectedAttributesView(selindex)" :key="sel.desc">
											<p class="group-head">{{sel[0].desc}}</p>
											<div v-for="item in sel" :key="item.selkey" style="margin-left: 20px;">
												{{item.item_desc}}
												<span class="mdi mdi-close" @click="selarr.splice(selarr.indexOf(item.selkey), 1)"></span>
											</div>
										</div>
									</div>
									<div v-if="selindex < selectionsArray.length - 1">OR</div>
								</div>

								<div class="addor" @click="addSelectionsGroup">Add an OR selections group</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import SubTreeItem from "@/components/SubTreeItem";
export default {
	name: "DemoEdit",
	components: {SubTreeItem},
	mixins: [globalMixin],
	props: {

	},
	data: function () {
		return {
			store: store,
			fetching: false,

			editDemoName: '',
			selectionsArray: [[]],
			activeSelectionIndex: 0,

			loadFinished: false,
			isGlobalDemo: false,
			siteAccessId: 0,

			showFor: 'global', //xtra, gfk, global
			oldShowFor: 'global', //a helper for our watcher
			specificSurveyId: 0, //converted to null in PHP for db
			specificSurvQuestions: [],

			mouseChangeType: '',
			openGroups: [],
		}
	},
	computed: {
		selections: {
			get() {
				return this.selectionsArray[this.activeSelectionIndex]
			},
			set(value) {
				this.selectionsArray[this.activeSelectionIndex] = value
			}
		},
		sourceDemoOb() {
			if(this.store.editDemoId === 0) {
				return {
					id: 0,
					survey_code: '',
					name: '',
					userid: this.store.user.id,
					type: 'demographic',
					infolder: 0,
					settings: [],
					siteAccessId: 0,
				}
			}
			return store.demographics.find(item => item.id === this.store.editDemoId)
		},
		displayedAttributes() {
			if(this.showFor === 'global') {
				return this.store.demographic_attributes.filter(item => item.xtra === true && item.gfk === true)
			}
			else if(this.showFor === 'xtra') {
				return this.store.demographic_attributes.filter(item => item.xtra === true)
			}
			else if(this.showFor === 'gfk') {
				return this.store.demographic_attributes.filter(item => item.gfk === true)
			}
			return []
		},

	},
	methods: {
		addSelectionsGroup() {
			this.selectionsArray.push([])
			this.activeSelectionIndex = this.selectionsArray.length - 1
		},
		removeSelectionsGroup(index) {
			if(this.selectionsArray.length > 1) {
				this.selectionsArray.splice(index, 1)
				if(!this.selectionsArray[this.activeSelectionIndex]) this.activeSelectionIndex = 0
			}
		},
		selectedAttributesView(selIndex) {
			let mysels = JSON.parse(JSON.stringify(this.selectionsArray[selIndex]))
			for(let i=0; i<mysels.length; i++) {
				let s = mysels[i]
				let sarr = s.split("|")
				let skey = sarr[0]
				let sval = parseInt(sarr[1])
				if(sval < 10) sval = '00'+sval
				else if(sval < 100) sval = '0'+sval
				mysels[i] = skey+'|'+sval
			}
			mysels.sort()
			let ret = {}
			for(let s of mysels) {
				let sarr = s.split("|")
				let skey = sarr[0]
				let sval = parseInt(sarr[1])
				let att = this.store.demographic_attributes.find(item => item.demo_att_id === skey)
				if(att) {
					let opt = att.items.find(item => item.id === sval)
					if(opt) {
						if(!ret[skey]) ret[skey] = []
						ret[skey].push({desc: att.desc, item_desc: opt.item_desc, selkey: skey+'|'+sval})
					}
				}
			}
			return ret
		},
		toggleGroupShow(dgid) {
			if(this.openGroups.includes(dgid)) {
				let ind = this.openGroups.indexOf(dgid)
				this.openGroups.splice(ind, 1)
			}
			else {
				this.openGroups.push(dgid)
			}
		},
		selectionToggleDrag(elemId) {
			if(window.mouseIsDown === 1) {
				if(!this.selections.includes(elemId) && this.mouseChangeType === 'add') this.selections.push(elemId)
				else if(this.selections.includes(elemId) && this.mouseChangeType === 'remove') this.selections.splice(this.selections.indexOf(elemId), 1)
			}
		},
		selectionToggleStart(elemId) {
			if(!this.selections.includes(elemId)) this.mouseChangeType = 'add'
			else this.mouseChangeType = 'remove'
			if(!this.selections.includes(elemId) && this.mouseChangeType === 'add') this.selections.push(elemId)
			else if(this.selections.includes(elemId) && this.mouseChangeType === 'remove') this.selections.splice(this.selections.indexOf(elemId), 1)
		},
		clearAll() {
			this.selections = []
		},
		saveDemo() {
			if(this.editDemoName.length === 0) {
				alert('Please enter a name for your demographic');
				return false;
			}
			let empties = []
			let oktosave = false
			let counter = 0
			for(let a of this.selectionsArray) {
				if(a.length === 0) empties.push(counter)
				else oktosave = true
				counter++
			}
			empties.reverse()
			for(let e of empties) {
				this.removeSelectionsGroup(e)
			}
			if(oktosave === false) {
				alert('Please make some selections')
				return false;
			}
			let self = this;
			self.store.showLoader = true;
			let ownerId = this.store.user.id
			if(this.store.editDemoId > 0 && this.sourceDemoOb.userid > 0) { //editing existing
				ownerId = this.sourceDemoOb.userid
			}
			else {
				if (this.store.user.isAdmin === 1 && this.isGlobalDemo) ownerId = 0
			}
			axios.post(
				"/post/demographic-save.php",
				JSON.stringify({
					demoid: self.store.editDemoId,
					usercreds: self.store.usercreds,
					dname: self.editDemoName,
					selectionsArray: self.selectionsArray,
					survid: self.specificSurveyId,
					ownerId: ownerId,
					siteAccessId: self.siteAccessId,
				})
			).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.resp) { //handle returned objects for lists
					self.showKalert(ret.resp)
				}
				if (ret.demographics) {
					self.store.demographics = ret.demographics;
				}
				self.store.showLoader = false;
				self.store.demoEditOverlayShown = false;
			}).catch(function (error) {
				console.log(error);
				self.store.showLoader = false;
			});
		},
		getSurveyDemoAttributes() {
			let self = this
			axios.post(
				"/post/demographic-get-xtra-surv-opts.php",
				JSON.stringify({
					usercreds: self.store.usercreds,
					survey_code: self.specificSurveyId,
				})
			).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.survey_demo_attributes) {
					self.specificSurvQuestions = ret.survey_demo_attributes;
					self.updateSelectionsFromEdit()
				}
				self.store.showLoader = false;
			}).catch(function (error) {
				console.log(error);
				self.store.showLoader = false;
			});
		},
		updateSelectionsFromEdit() { //run on mount and after specific survey info is chosen
			let arrindex = 0
			for(let arr of this.sourceDemoOb.settings) {
				if(!this.selectionsArray[arrindex]) this.selectionsArray[arrindex] = []
				for(let item of arr) {
					for(let v of item.vals) {
						this.selectionsArray[arrindex].push(item.key+'|'+v)
					}
				}
				arrindex++
			}
		},
		confirmChangeDemoScope() {
			let impacted = false
			for(let s of this.selections) { //check if there are any selections that will be impacted
				let sarr = s.split("|")
				let skey = sarr[0]
				if((this.showFor === 'gfk' || this.showFor === 'global') && this.oldShowFor === 'xtra') {
					if(skey === 'occindustry') { //the only one that xtra has that gfk doesn't
						impacted = true
						break
					}
				}
				else if((this.showFor === 'xtra' || this.showFor === 'global') && this.oldShowFor === 'gfk') {
					if(!this.store.xtraDemoKeysOk.includes(skey)) {
						impacted = true
						break
					}
				}
			}
			if(impacted === true) {
				let c = window.confirm("If you change the demographic scope from "+this.oldShowFor+" to "+this.showFor+", some demographic attributes may be lost.  Are you sure?")
				if(c !== false) { //user approved, make the changes
					let newsels = []
					if(this.showFor === 'xtra') {
						for(let s of this.selections) {
							let sarr = s.split("|")
							let skey = sarr[0]
							if(this.store.xtraDemoKeysOk.includes(skey)) newsels.push(s)
						}
						this.selections = newsels
					}
					else if(this.showFor === 'gfk') {
						for(let s of this.selections) {
							let sarr = s.split("|")
							let skey = sarr[0]
							if(this.store.gfkDemoKeysOk.includes(skey) || s.includes('dematt_')) newsels.push(s)
						}
						this.selections = newsels
					}
					else if(this.showFor === 'global') {
						for(let s of this.selections) {
							let sarr = s.split("|")
							let skey = sarr[0]
							if(this.store.xtraDemoKeysOk.includes(skey) && skey !== 'occindustry') newsels.push(s)
						}
						this.selections = newsels
					}
					this.oldShowFor = this.showFor
				}
				else { //user cancelled the change
					this.showFor = this.oldShowFor //revert
				}
			}
			else {
				this.oldShowFor = this.showFor
			}
		},
	},
	watch: {
		showFor() {
			if(this.loadFinished && this.selections.length > 0 && this.oldShowFor !== this.showFor) {
				this.confirmChangeDemoScope()
			}
		},
		specificSurveyId() {
			if(this.loadFinished === true) {
				let newsels = [];
				let hasChanged = false
				let specificKeys = ['area']
				for (let s of this.selections) {
					let sarr = s.split("|")
					let skey = sarr[0]
					if (specificKeys.includes(skey)) hasChanged = true //do not add this option to selections as it doesn't apply to new survey
					else newsels.push(s)
				}
				if (hasChanged === true) this.selections = newsels
				this.getSurveyDemoAttributes()
			}
		},
	},
	mounted() {
		this.selections = []
		this.updateSelectionsFromEdit()
		this.editDemoName = this.sourceDemoOb.name
		//determine if this is an All Users demo (AL only) or just for the active user
		if(this.store.user.isAdmin === 1 && this.sourceDemoOb.userid === 0) this.isGlobalDemo = true
		else this.isGlobalDemo = false
		//determine if this is an All Surveys demo or just a single survey (AL only)
		this.specificSurveyId = 0
		if(this.store.user.isAdmin === 1 && this.sourceDemoOb.survey_code) {
			this.specificSurveyId = this.sourceDemoOb.survey_code
			this.getSurveyDemoAttributes()
		}
		//determine if this is GFK, Xtra or Both
		if(this.sourceDemoOb.xtra === true && this.sourceDemoOb.gfk === true) this.showFor = 'global'
		else if(this.sourceDemoOb.xtra === true) this.showFor = 'xtra'
		else if(this.sourceDemoOb.gfk === true) this.showFor = 'gfk'
		else this.showFor = 'global'
		this.oldShowFor = this.showFor //set the helper
		this.siteAccessId = this.sourceDemoOb.siteAccessId
		window.setTimeout(function() {
			this.loadFinished = true
		}.bind(this), 1000)
		window.mouseIsDown = 0
	}
}
</script>

<style scoped>
.dhead {
	font-weight: bold;
	line-height: 1.4;
	margin-top: 15px;
	margin-bottom: 5px;
	display: inline-block;
}
.popup-container {
	width: 1100px;
}
.demos-halves-flex {
	display: flex;
	gap: 40px;
}
.demo-left-side {
	width: 48%;
}
.demo-right-side {
	width: 48%;
}
.shown-selections {
	border: 1px solid #CCC;
	padding: 20px;
	line-height: 1.4;
}
.shown-selections .mdi-close {
	cursor: pointer;
	color: #F00;
	margin-left: 5px;
}
.shown-selections .mdi-close:hover {
	color: #A00;
}
.attributes-list {
	overflow-y: auto;
	height: calc(100vh - 410px);
}
.group-head {
	margin-top: 10px;
}
.addor {
	cursor: pointer;
	color: #28A;
	text-decoration: underline;
	margin: 10px 0;
}
.addtogroup {
	cursor: pointer;
	color: #28A;
	font-size: 10px;
}
.arraygroup {
	border: 1px solid #CCC;
	padding: 10px;
	margin: 10px 0;
}
.activearray {
	background: var(--greylight);
}
</style>