<template>
	<div>
		<TopTabBar :hidebelowtabs="true">
			<template v-slot:belowtabs>
<!--				<div class="flex" style="height: 100%;">-->
<!--					<span class="freq-shortcut" @click="shortcutStatistics('clear')">Clear</span>-->
<!--					<span class="freq-shortcut" @click="shortcutStatistics('statisticMost')">Most</span>-->
<!--					<span class="freq-shortcut" @click="shortcutStatistics('statisticCHund')">C00s</span>-->
<!--					<span class="freq-shortcut" @click="shortcutStatistics('statisticCPct')">C%</span>-->
<!--				</div>-->
			</template>
		</TopTabBar>
		<div class="body-hold hide-below-tabs">
			<ListsChooser v-on:filterupdate="filterText = $event" :filterboxtopborder="true" :nointeriortabs="true">
				<template v-slot:left_items>
					<div v-if="store.reportType === 'multiRank'" style="margin-bottom: 15px;">
						<input class="rfinputfield" min="2" max="5" style="width: 50px; margin-right: 10px;" type="number" v-model="store.numberStationsInMultiStationRank" />
						<span class="label">Number of stations per combo (2-5)</span>
					</div>

					<p class="boldme grouphead" style="margin-top: 0;">Global Statistics</p>
					<div v-for="st in filteredGlobalStatistics" :key="st.id" class="checklist-item">
						<div class="ch-and-title" @click="toggleStatisticInSelection(st)">
						<span class="toggler">
							<i v-if="store.selectionObs.statistics.some(item => item.id === st.id)" class="mdi mdi-check-bold"></i>
						</span>
							<span class="label">{{st.name}}</span>
						</div>
						<InfoIcon addclasses="small" :id="'st'+st.id" @click="popoverClicked('st'+st.id)"></InfoIcon>
						<PopoverItem v-if="popoverIdShown === 'st'+st.id" :title="st.name" v-on:close="hidePopovers">
							<template v-slot:content>
								<p class="api-info">API ID: {{st.id}}<br/><br/></p>
								<p v-html="st.textinfo"></p>
							</template>
						</PopoverItem>
					</div>

					<p class="boldme grouphead">GFK Markets Only</p>
					<div v-for="st in filteredGFKStatistics" :key="st.id" class="checklist-item">
						<div class="ch-and-title" @click="toggleStatisticInSelection(st)">
						<span class="toggler">
							<i v-if="store.selectionObs.statistics.some(item => item.id === st.id)" class="mdi mdi-check-bold"></i>
						</span>
							<span class="label">{{st.name}}</span>
						</div>
						<InfoIcon addclasses="small" :id="'st'+st.id" @click="popoverClicked('st'+st.id)"></InfoIcon>
						<PopoverItem v-if="popoverIdShown === 'st'+st.id" :title="st.name" v-on:close="hidePopovers">
							<template v-slot:content>
								<p class="api-info">API ID: {{st.id}}<br/><br/></p>
								<p v-html="st.textinfo"></p>
							</template>
						</PopoverItem>
					</div>

					<p class="boldme grouphead">Xtra Markets Only</p>
					<div v-for="st in filteredXtraStatistics" :key="st.id" class="checklist-item">
						<div class="ch-and-title" @click="toggleStatisticInSelection(st)">
						<span class="toggler">
							<i v-if="store.selectionObs.statistics.some(item => item.id === st.id)" class="mdi mdi-check-bold"></i>
						</span>
							<span class="label">{{st.name}}</span>
						</div>
						<InfoIcon addclasses="small" :id="'st'+st.id" @click="popoverClicked('st'+st.id)"></InfoIcon>
						<PopoverItem v-if="popoverIdShown === 'st'+st.id" :title="st.name" v-on:close="hidePopovers">
							<template v-slot:content>
								<p class="api-info">API ID: {{st.id}}<br/><br/></p>
								<p v-html="st.textinfo"></p>
							</template>
						</PopoverItem>
					</div>

					<div v-if="store.reportType !== 'multiRank'">
						<p class="boldme grouphead">Splits (GFK Only)</p>
						<select v-model="store.qtrHrSplitType" style="margin-bottom: 5px;">
							<option value="none">No time split</option>
							<option value="qtrhr">Quarter Hours</option>
							<option value="halfhour">Half Hours</option>
							<option value="hour">Hours</option>
						</select>
<!--						<div>-->
<!--							<input id="splqtr" type="checkbox" v-model="store.splitByQuarterHours">-->
<!--							<label for="splqtr">Quarter Hours</label>-->
<!--						</div>-->
						<div>
							<input id="spldow" type="checkbox" v-model="store.splitByDayOfWeek">
							<label for="spldow">Day of Week</label>
						</div>
						<div>
							<input id="splpol" type="checkbox" v-model="store.splitByPlaceOfListen">
							<label for="splpol">Place of Listening</label>
						</div>
						<div>
							<input id="splatyp" type="checkbox" v-model="store.splitByAudioType">
							<label for="splatyp">Radio Type (2023+)</label>
						</div>
					</div>
				</template>
				<template v-slot:rightside>
					<draggable v-model="mylist" item-key="index" v-bind="dragOptions">
						<template #item="{index}">
							<SortableInternal type="statistic" :objid="mylist[index].id" v-on:remove="toggleStatisticInSelection(mylist[index])"></SortableInternal>
						</template>
					</draggable>
				</template>
			</ListsChooser>

		</div>

	</div>
</template>

<script>
import SortableInternal from "@/components/SortableInternal";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import draggable from 'vuedraggable'
import InfoIcon from "@/components/InfoIcon";
import PopoverItem from "@/components/PopoverItem";
import TopTabBar from "@/components/TopTabBar.vue";
import ListsChooser from "@/components/views/ListsChooser.vue";
export default {
	name: "StatisticsView",
	components: {
		ListsChooser,
		TopTabBar,
		PopoverItem,
		InfoIcon,
		SortableInternal,
		draggable
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			filterText: '',

			popoverIdShown: null,
			allowedForMultiRankStatIds: ['cume','stationListenedMost','shareToAll','avgAud',
				'tslHrs','cumePct','tslHrsDecimal','tslMins'],
		}
	},
	computed: {
		reportType() {
			return this.store.reportType;
		},
		mylist: {
			get() {
				return this.store.selectionObs.statistics;
			},
			set(value) {
				this.store.selectionObs.statistics = value;
			}
		},
		dragOptions() {
			return {
				animation: 200,
				disabled: false,
				ghostClass: "ghost-drag"
			}
		},
		filteredGlobalStatistics() {
			let mystats = this.store.statistics.filter(item => item.gfk === true && item.xtra === true);
			if(this.reportType === 'multiRank') mystats = mystats.filter(item => this.allowedForMultiRankStatIds.includes(item.id))
			if(this.filterText.length === 0) return mystats;
			else { //either already selected or matching text
				return mystats.filter(item => this.store.selectionObs.statistics.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredXtraStatistics() {
			let mystats = this.store.statistics.filter(item => item.gfk === false && item.xtra === true);
			if(this.reportType === 'multiRank') mystats = mystats.filter(item => this.allowedForMultiRankStatIds.includes(item.id))
			if(this.filterText.length === 0) return mystats;
			else { //either already selected or matching text
				return mystats.filter(item => this.store.selectionObs.statistics.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredGFKStatistics() {
			let mystats = this.store.statistics.filter(item => item.gfk === true && item.xtra !== true);
			if(this.reportType === 'multiRank') mystats = mystats.filter(item => this.allowedForMultiRankStatIds.includes(item.id))
			if(this.filterText.length === 0) return mystats;
			else { //either already selected or matching text
				return mystats.filter(item => this.store.selectionObs.statistics.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
	},
	methods: {
		popoverClicked(tid) {
			this.popoverIdShown = tid
		},
		hidePopovers() {
			this.popoverIdShown = null
		},
		// shortcutStatistics(val) {
		// 	let sels = [];
		// 	if(val === 'clear') {
		// 		sels = [];
		// 	}
		// 	else if(val === "statisticMost") {
		// 		sels = [2,4,5,6,7,8,10,9];
		// 	}
		// 	else if(val === "statisticCHund") {
		// 		sels = [11,13,15,17,19,21,23,25];
		// 	}
		// 	else if(val === "statisticCPct") {
		// 		sels = [1,12,14,16,18,20,22,24];
		// 	}
		// 	let arr = []
		// 	for(let s of sels) {
		// 		arr.push(this.store.statistics.find(item => item.id === s))
		// 	}
		// 	this.store.selectionObs.statistics = arr;
		// },
		cleanTimeSplitsFromTable() {
			this.store.tableLayout.tables = this.store.tableLayout.tables.filter(item => item.id !== 'qtrHrs')
			this.store.tableLayout.columns = this.store.tableLayout.columns.filter(item => item.id !== 'qtrHrs')
			this.store.tableLayout.rows = this.store.tableLayout.rows.filter(item => item.id !== 'qtrHrs')
		},
	},
	watch: {
		'store.qtrHrSplitType': {
			handler() {
				if(this.store.qtrHrSplitType === 'none') {
					this.store.qtHrSplitType = 'none'
					this.store.splitByQuarterHours = false
					this.store.selectionObs.qtrHrs = [{id: 0, name: 'All', type: 'qtrhr'}]
					this.store.tableLayout.tables = this.store.tableLayout.tables.filter(item => item.id !== 'qtrHrs')
					this.store.tableLayout.columns = this.store.tableLayout.columns.filter(item => item.id !== 'qtrHrs')
					this.store.tableLayout.rows = this.store.tableLayout.rows.filter(item => item.id !== 'qtrHrs')
				}
				else {
					this.cleanTimeSplitsFromTable()
					this.store.splitByQuarterHours = true
					if (this.store.qtrHrSplitType === 'qtrhr') {
						this.store.selectionObs.qtrHrs = this.store.qtrHrs
						this.store.tableLayout.rows.push({id: 'qtrHrs', name: 'Quarter Hour'})
					}
					else if (this.store.qtrHrSplitType === 'halfhour') {
						this.store.selectionObs.qtrHrs = this.store.halfhours
						this.store.tableLayout.rows.push({id: 'qtrHrs', name: 'Half Hour'})
					}
					else if (this.store.qtrHrSplitType === 'hour') {
						this.store.selectionObs.qtrHrs = this.store.hours
						this.store.tableLayout.rows.push({id: 'qtrHrs', name: 'Hour'})
					}
				}
			}
		},
		// 'store.splitByQuarterHours': {
		// 	handler() {
		// 		if(this.store.splitByQuarterHours === true) {
		// 			this.store.selectionObs.qtrHrs = this.store.qtrHrs
		// 			this.store.tableLayout.rows.push({id: 'qtrHrs', name: 'Quarter Hour'})
		// 		}
		// 		else {
		// 			this.store.selectionObs.qtrHrs = [{id: 0, name: 'All', type: 'qtrhr'}]
		// 			this.store.tableLayout.tables = this.store.tableLayout.tables.filter(item => item.id !== 'qtrHrs')
		// 			this.store.tableLayout.columns = this.store.tableLayout.columns.filter(item => item.id !== 'qtrHrs')
		// 			this.store.tableLayout.rows = this.store.tableLayout.rows.filter(item => item.id !== 'qtrHrs')
		// 		}
		// 	}
		// },
		'store.splitByPlaceOfListen': {
			handler() {
				if(this.store.splitByPlaceOfListen === true) {
					this.store.selectionObs.places = this.store.places
					this.store.tableLayout.columns.push({id: 'places', name: 'Place Of Listening'})
				}
				else {
					this.store.selectionObs.places = [{id: 0, name: 'All', type: 'place'}]
					this.store.tableLayout.tables = this.store.tableLayout.tables.filter(item => item.id !== 'places')
					this.store.tableLayout.columns = this.store.tableLayout.columns.filter(item => item.id !== 'places')
					this.store.tableLayout.rows = this.store.tableLayout.rows.filter(item => item.id !== 'places')
				}
			}
		},
		'store.splitByAudioType': {
			// deep: true,
			handler() {
				this.store.tableLayout.tables = this.store.tableLayout.tables.filter(item => item.id !== 'audioTypes')
				this.store.tableLayout.columns = this.store.tableLayout.columns.filter(item => item.id !== 'audioTypes')
				this.store.tableLayout.rows = this.store.tableLayout.rows.filter(item => item.id !== 'audioTypes')
				if(this.store.splitByAudioType === true) {
					this.store.selectionObs.audioTypes = this.store.audioTypes
					this.store.tableLayout.columns.push({id: 'audioTypes', name: 'Radio Type'})
				}
				else {
					this.store.selectionObs.audioTypes = [{id: 0, name: 'Total Radio', type: 'audioType'}]
				}
			}
		},
		'store.splitByDayOfWeek': {
			handler() {
				this.store.tableLayout.tables = this.store.tableLayout.tables.filter(item => item.id !== 'dows')
				this.store.tableLayout.columns = this.store.tableLayout.columns.filter(item => item.id !== 'dows')
				this.store.tableLayout.rows = this.store.tableLayout.rows.filter(item => item.id !== 'dows')
				if(this.store.splitByDayOfWeek === true) {
					this.store.selectionObs.dows = this.store.dows
					this.store.tableLayout.columns.push({id: 'dows', name: 'Day of Week'})
				}
				else {
					this.store.selectionObs.dows = [{id: 'all', name: 'All', type: 'dow'}]
				}
			}
		},
	},
	mounted() {
		document.documentElement.style.scrollBehavior = 'auto';
		setTimeout(() => window.scrollTo(0, 0), 5); //tiny timeout to let page load first
	}
}
</script>

<style scoped>
.body-hold {
	margin: 20px;
	margin-top: 110px !important;
}
.grouphead {
	font-size: 16px;
	margin: 20px 0 10px;
}
</style>