<template>
	<div>
		<TopTabBar>
			<template v-slot:belowtabs>
				<div class="flex" style="height: 100%;">
					<span class="freq-shortcut" @click="shortcutDemos('clear')">Clear</span>
					<span class="freq-shortcut" @click="baseTargetButtonHandler" :class="{active : baseTargetStatus !== 'none'}">{{baseTargetButtonText}}</span>
					<span v-if="baseTargetStatus !== 'none'" class="base-text">{{baseTargetInstructions}}</span>
				</div>
			</template>
		</TopTabBar>
		<div class="body-hold">
			<div class="view-holder nostyle">
				<ListsChooser v-on:filterupdate="filterText = $event">
					<template v-slot:interior_tabs>
						<InteriorTab id="global" name="Global" :isactive="demogroupactive === 'global'" @set="demogroupactive = $event"></InteriorTab>
<!--						<InteriorTab id="survey" name="Survey" :isactive="demogroupactive === 'survey'" @set="demogroupactive = $event"></InteriorTab>-->
						<InteriorTab id="attr" name="Attributes" :isactive="demogroupactive === 'attr'" @set="demogroupactive = $event"></InteriorTab>
						<InteriorTab id="site" name="Site" :isactive="demogroupactive === 'site'" @set="demogroupactive = $event"></InteriorTab>
						<InteriorTab id="user" name="User" :isactive="demogroupactive === 'user'" @set="demogroupactive = $event"></InteriorTab>
					</template>
					<template v-slot:left_items>
						<div v-if="demogroupactive === 'global'">
							<p class="boldme grouphead" style="margin-top: 0;">All People</p>
							<DemoViewGroup :demos="filterGlobalDemosAllPeople" :showfolders="false" v-on:toggledemo="toggleDemoSelectionLocal($event)"></DemoViewGroup>
							<p class="boldme grouphead">Males</p>
							<DemoViewGroup :demos="filterGlobalDemosMales" :showfolders="false" v-on:toggledemo="toggleDemoSelectionLocal($event)"></DemoViewGroup>
							<p class="boldme grouphead">Females</p>
							<DemoViewGroup :demos="filterGlobalDemosFemales" :showfolders="false" v-on:toggledemo="toggleDemoSelectionLocal($event)"></DemoViewGroup>
							<p class="boldme grouphead">Other</p>
							<DemoViewGroup :demos="filterGlobalDemosOther" :showfolders="false" v-on:toggledemo="toggleDemoSelectionLocal($event)"></DemoViewGroup>
						</div>
						<div v-else-if="demogroupactive === 'attr'">
							<p class="boldme grouphead" style="margin-top: 0;">For GFK Surveys Only</p>
							<div v-for="dg in store.demographic_groups" :key="dg.id">
								<div v-for="at in store.demographic_attributes.filter(item => item.group === dg.id)" :key="at.demo_att_id">
									<p class="dhead" @click="addAllAttributeDemos(at.demo_att_id)" style="user-select: none;">{{at.desc}}</p>
									<div v-for="(opt, ind) in filteredDemoAttributeItems(at.items)" style="user-select: none;" :key="ind"
									>
										<p class="ditem" @click="toggleDemoInSelectionAttr(at.demo_att_id+'|'+opt.id)">{{opt.item_desc}}</p>
									</div>
								</div>
							</div>
						</div>
						<div v-else-if="demogroupactive === 'survey'">
							<div v-for="dem in filteredSurveySpecificGlobalDemos" :key="dem.id" class="checklist-item">
								<div class="ch-and-title" @click="toggleDemoSelectionLocal(dem)">
									<span class="toggler">
										<i v-if="store.selectionObs.demographics.some(item => item.id === dem.id)" class="mdi mdi-check-bold"></i>
									</span>
									<span class="label" :id="'dem'+dem.id" :title="'API ID: '+dem.id">{{dem.name}}</span>
								</div>
							</div>
						</div>
						<div v-else-if="demogroupactive === 'site'" style="margin-top: -20px;">
							<div v-if="store.sites.length > 0">
								<div v-for="sa in store.sites" :key="sa.id" class="">
									<p class="boldme grouphead">{{sa.name}}</p>
									<DemoViewGroup :demos="getDemosFromSiteId(sa.id)" v-on:toggledemo="toggleDemoSelectionLocal($event)"></DemoViewGroup>
								</div>
							</div>
						</div>
						<div v-else-if="demogroupactive === 'user'">
							<div>
								<span class="primary-button" @click="createNewDemo" >
									<i class="mdi mdi-plus"></i> Create New
								</span>
								<span class="primary-button" @click="store.demosManageOverlayShown = true" style="margin-left:10px;">
									<i class="mdi mdi-cog"></i> Manage
								</span>
							</div>
							<DemoViewGroup :demos="filteredUserSavedAllSurveyDemos" v-on:toggledemo="toggleDemoSelectionLocal($event)"></DemoViewGroup>
							<p class="boldme grouphead">
								User Survey Specific
								<i class="mdi mdi-plus-box-outline" @click="createNewDemo" style="cursor:pointer;" title="New Demographic"></i>
							</p>
							<DemoViewGroup :demos="filteredSurveySpecificUserDemos" v-on:toggledemo="toggleDemoSelectionLocal($event)"></DemoViewGroup>
						</div>
					</template>
					<template v-slot:rightside>
						<draggable v-model="mylist" item-key="index" v-bind="dragOptions">
							<template #item="{index}">
								<SortableInternal type="demographic" :objid="mylist[index].id" v-on:remove="removeDemoSelection(mylist[index])"></SortableInternal>
							</template>
						</draggable>
					</template>
				</ListsChooser>
			</div>
		</div>
	</div>
</template>

<script>
import SortableInternal from "@/components/SortableInternal";
import DemoViewGroup from "@/components/demos/DemoViewGroup";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import draggable from 'vuedraggable'
import TopTabBar from "@/components/TopTabBar.vue";
import ListsChooser from "@/components/views/ListsChooser.vue";
import InteriorTab from "@/components/InteriorTab.vue";
export default {
	name: "DemographicsView",
	components: {
		InteriorTab,
		ListsChooser,
		TopTabBar,
		DemoViewGroup,
		SortableInternal,
		draggable
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			filterText: '',

			demogroupactive: 'global',
			baseTargetStatus: 'none', //none, selectbase, selected
			baseTargetDemoId: null,
		}
	},
	computed: {
		mylist: {
			get() {
				return this.store.selectionObs.demographics;
			},
			set(value) {
				this.store.selectionObs.demographics = value;
			}
		},
		dragOptions() {
			return {
				animation: 200,
				disabled: false,
				ghostClass: "ghost-drag"
			}
		},
		reportType() {
			return this.store.reportType;
		},
		surveyCodesChosen() {
			let sl = [];
			for(let s of this.store.selectionObs.surveys) {
				for(let ss of s.survobs) {
					sl.push(ss.id)
				}
			}
			return sl
		},
		filteredGlobalDemos() {
			let mydemos = this.store.demographics.filter(item => (item.userid === 0 && !item.survey_code && item.isAttribute === false && item.isCombinedDemo === false));
			if(this.filterText.length === 0) return mydemos;
			else { //either already selected or matching text
				return mydemos.filter(item => this.store.selectionObs.demographics.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filterGlobalDemosAllPeople() {
			return this.filteredGlobalDemos.filter(item => item.name.toLowerCase().startsWith('p '));
		},
		filterGlobalDemosMales() {
			return this.filteredGlobalDemos.filter(item => item.name.toLowerCase().startsWith('m '));
		},
		filterGlobalDemosFemales() {
			return this.filteredGlobalDemos.filter(item => item.name.toLowerCase().startsWith('f '));
		},
		filterGlobalDemosOther() {
			return this.filteredGlobalDemos.filter(item => !item.name.toLowerCase().startsWith('m ') && !item.name.toLowerCase().startsWith('f ') && !item.name.toLowerCase().startsWith('p '));
		},
		filteredUserSavedAllSurveyDemos() {
			let mydemos = this.store.demographics.filter(item => (item.userid !== 0 && !item.survey_code && item.siteAccessId === 0 && item.userid === this.store.user.id));
			if(this.filterText.length === 0) return mydemos;
			else { //either already selected or matching text
				return mydemos.filter(item => this.store.selectionObs.demographics.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredSurveySpecificGlobalDemos() {
			let mydemos = this.store.demographics.filter(item => (item.userid === 0 && this.surveyCodesChosen.includes(item.survey_code)));
			if(this.filterText.length === 0) return mydemos;
			else { //either already selected or matching text
				return mydemos.filter(item => this.store.selectionObs.demographics.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredSurveySpecificUserDemos() {
			let mydemos = this.store.demographics.filter(item => (item.userid === this.store.user.id && this.surveyCodesChosen.includes(item.survey_code) && item.siteAccessId === 0  && item.userid === this.store.user.id));
			if(this.filterText.length === 0) return mydemos;
			else { //either already selected or matching text
				return mydemos.filter(item => this.store.selectionObs.demographics.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		baseTargetDemo() {
			if(this.baseTargetDemoId === null) return null
			return this.store.demographics.find(item => item.id === this.baseTargetDemoId)
		},
		baseTargetButtonText() {
			if(this.baseTargetStatus === 'none') return 'Choose Base Target'
			else if(this.baseTargetStatus === 'selectbase') return 'Cancel Base Target'
			else if(this.baseTargetStatus === 'selected') return 'Cancel Base Target'
			return '';
		},
		baseTargetInstructions() {
			if(this.baseTargetStatus === 'selectbase') return 'Click on ONE demographic to select it as the base target.'
			else if(this.baseTargetStatus === 'selected') {
				let x = 'Select demographics to add combined with your base target: '
				if(this.baseTargetDemo) x += this.baseTargetDemo.name
				return x
			}
			return '';
		},
	},
	methods: {
		addAllAttributeDemos(demo_att_id) {
			demo_att_id = demo_att_id.replace('dematt_', 'dematt')
			if(this.baseTargetStatus === 'selectbase') {
				return false //these can't be chosen as base
			}
			else if(this.baseTargetStatus === 'selected') { //new base target x attribute demo
				let demarr = this.store.demographics.filter(item => item.isAttribute === true)
				for (let attrob of demarr) {
					if (attrob.id.startsWith(demo_att_id + '|')) {
						let ob = this.createDemoTempFromBase(this.baseTargetDemo, attrob)
						if(!this.store.demographics.find(item => item.id === ob.id)) {
							this.store.demographics.push(ob)
						}
						this.store.selectionObs.demographics.push(ob)
					}
				}
			}
			else { //standard selection
				let temparr = this.store.selectionObs.demographics
				let demarr = this.store.demographics.filter(item => item.isAttribute === true)
				for (let dem of demarr) {
					if (dem.id.startsWith(demo_att_id + '|')) {
						let found = temparr.find(item => item.id === dem.id)
						if (!found) temparr.push(dem)
					}
				}
				this.store.selectionObs.demographics = temparr
			}
		},
		toggleDemoInSelectionAttr(obid) {
			obid = obid.replace('dematt_', 'dematt')
			if(this.baseTargetStatus === 'selectbase') {
				return false //these can't be chosen as base
			}
			else if(this.baseTargetStatus === 'selected') { //new base target x attribute demo
				let attrob = this.store.demographics.find(item => item.id === obid)
				let ob = this.createDemoTempFromBase(this.baseTargetDemo, attrob)
				if(!this.store.demographics.find(item => item.id === ob.id)) {
					this.store.demographics.push(ob)
				}
				this.store.selectionObs.demographics.push(ob)
			}
			else {
				let temparr = this.store.selectionObs.demographics
				if (temparr.some(elem => elem.id === obid)) temparr = temparr.filter(item => item.id !== obid) //remove it
				else {
					let ob = this.store.demographics.find(item => item.id === obid)
					if (ob) temparr.push(ob) //add it at end of array
				}
				this.store.selectionObs.demographics = temparr;
			}
		},
		toggleDemoSelectionLocal(demoOb) {
			if(this.baseTargetStatus === 'selectbase') {
				this.selectBaseTarget(demoOb)
			}
			else if(this.baseTargetStatus === 'selected') {
				let ob = this.createDemoTempFromBase(this.baseTargetDemo, demoOb)
				if(!this.store.demographics.find(item => item.id === ob.id)) {
					this.store.demographics.push(ob)
				}
				this.store.selectionObs.demographics.push(ob)
			}
			else this.toggleDemoInSelection(demoOb)
		},
		removeDemoSelection(demoOb) {
			this.store.selectionObs.demographics = this.store.selectionObs.demographics.filter(item => item.id !== demoOb.id)
		},
		baseTargetButtonHandler() {
			if(this.baseTargetStatus === 'none') this.chooseBaseTarget()
			else if(this.baseTargetStatus === 'selectbase') this.cancelBaseTarget()
			else if(this.baseTargetStatus === 'selected') this.cancelBaseTarget()
		},
		chooseBaseTarget() {
			this.baseTargetStatus = 'selectbase'
		},
		selectBaseTarget(demo) {
			this.baseTargetStatus = 'selected'
			this.baseTargetDemoId = demo.id
		},
		cancelBaseTarget() {
			this.baseTargetStatus = 'none'
			this.baseTargetDemoId = null
		},
		shortcutDemos(val) { //clear, occ
			let sels = [];
			if(val === 'clear') {
				sels = [];
			}
			this.store.selectionObs.demographics = sels;
		},
		getDemosFromSiteId(sid) {
			let mydemos = this.store.demographics.filter(item => item.siteAccessId === sid)
			if(this.filterText.length === 0) return mydemos
			else { //either already selected or matching text
				return mydemos.filter(item => this.store.selectionObs.demographics.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
		},
		filteredDemoAttributeItems(items) {
			if(this.filterText.length > 0) {
				return items.filter(item => item.item_desc.toLowerCase().includes(this.filterText.toLowerCase()))
			}
			return items
		},
	},
	watch: {},
	mounted() {
		document.documentElement.style.scrollBehavior = 'auto';
		setTimeout(() => window.scrollTo(0, 0), 5); //tiny timeout to let page load first
	}
}
</script>

<style scoped>
.view-holder {
	margin: 0 20px 20px;
	padding: 0;
}
.grouphead {
	font-size: 16px;
	margin: 20px 0 10px;
}
.dhead {
	font-size: 14px;
	margin: 10px 0 5px;
	font-weight: bold;
}
.ditem {
	margin-left: 10px;
	font-size: 12px;
}
</style>