<template>
	<div class="loader-overlay">
		<div class="popup-container">
			<div class="popup-topbar">
				<span class="bold">Station Combos List</span>
				<div class="filterbox-hold">
					<input type="text" placeholder="Filter station combos..." v-model="filterText" class="filterbox" />
				</div>
				<span class="closer" @click="store.combosManageOverlayShown = false">
					<i class="mdi mdi-close"></i>
				</span>
			</div>

			<div class="popup-insides">
				<div v-if="fetching">Loading...</div>
				<div v-else>
					<div style="text-align: left; margin-bottom: 15px;">
						<span class="primary-button" @click="createNewCombo()" >
							<i class="mdi mdi-plus"></i> Create New
						</span>
					</div>
					<div v-if="store.sitesAdmin.length > 0">
						<div v-for="sa in store.sitesAdmin" :key="sa.id" class="">
							<p class="site-group-name">--- {{sa.name}}</p>
							<ComboManageGroup :siteaccessid="sa.id" :combos="getCombosFromSiteId(sa.id)" v-on:editcombo="editCombo($event)" v-on:deletecombo="delCombo($event)"></ComboManageGroup>
						</div>
						<p class="site-group-name">--- Just Me</p>
					</div>
					<ComboManageGroup :combos="filteredCombos" v-on:editcombo="editCombo($event)" v-on:deletecombo="delCombo($event)"></ComboManageGroup>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import axios from "axios";
import ComboManageGroup from "@/components/combos/ComboManageGroup";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "CombosManage",
	components: {
		ComboManageGroup
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			fetching: false,
			jobslist: [],
			filterText: '',
		}
	},
	computed: {
		filteredCombos() {
			let combos = this.store.stationcombos.filter(item => item.userid === this.store.user.id && item.siteAccessId === 0)
			if(this.store.user.isAdmin === 1) combos = this.store.stationcombos
			if(this.filterText.length > 0) {
				return combos.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()))
			}
			return combos
		},
		ownerId() {
			if(this.store.user.id === 1 && this.chosenOwnerId) {
				return this.chosenOwnerId;
			}
			return this.store.user.id;
		},
	},
	methods: {
		getCombosFromSiteId(sid) {
			let combos = this.store.stationcombos.filter(item => item.userid === this.store.user.id && item.siteAccessId === sid)
			if(this.store.user.isAdmin === 1) combos = this.store.stationcombos
			if(this.filterText.length > 0) {
				return combos.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
			return combos
		},
		editCombo(cid) {
			this.store.editComboId = cid
			this.store.comboEditOverlayShown = true
		},
		getMarketFromStation(st) {
			let mkt = this.store.markets.find(item => item.id === st.mktid);
			if(mkt) return mkt.name + " " + mkt.state;
			return '';
		},
		delCombo(cid) {
			let c = window.confirm("Are you sure you wish to delete this combo?");
			if(c !== false && this.fetching === false) {
				this.fetching = true;
				let self = this;
				axios.post(
					"/post/stationcombo-delete.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
						cid: cid,
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if (ret.resp) {
						self.showKalert(ret.resp)
					}
					self.fetching = false;
					if(ret.delcid) {
						let tc = self.store.stationcombos.filter(item => item.id !== ret.delcid);
						self.store.stationcombos = tc;
					}
				}).catch(function (error) {
					console.log(error);
					self.fetching = false;
				});
			}
		},
	},
	watch: {
		chosenMarketId() {
			if(!this.expandedMktIds.includes(this.chosenMarketId)) {
				this.expandedMktIds.push(this.chosenMarketId);
			}
		},
	},
	mounted() {
		if(this.store.user.id === 1) {
			this.chosenOwnerId = 1;
		}
	}
}
</script>

<style scoped>
.site-group-name {
	font-size: 15px;
	font-weight: bold;
	margin-top: 15px;
	margin-bottom: 5px;
	text-align: left;
}
</style>