<template>
	<div>
		<MenuBarExternal></MenuBarExternal>
		<div class="body-hold tabsNotShown">
			<div v-if="store.returnedData.length > 0 " class="view-holder">
				<p class="note-text-top">Report generated by {{runUserEmail}} at {{runDateTime}} (Sydney time)</p>
				<RFResultContentPage></RFResultContentPage>
			</div>
			<div v-else-if="fetchingInitialDone === true" class="view-holder">
				{{returnedErrorMessage}}
			</div>
		</div>

	</div>
</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import RFResultContentPage from "@/components/result-tables/RFResultContentPage.vue";
import MenuBarExternal from "@/components/MenuBarExternal.vue";
export default {
	name: "HomePage",
	components: {
		MenuBarExternal,
		RFResultContentPage

	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			fetchingInitial: false,
			fetchingInitialDone: false,

			shareableLinkSlug: '',
			runDateTime: null,
			runUserEmail: null,
			returnedErrorMessage: null,
		}
	},
	computed: {

	},
	methods: {
		fetchReportData() {
			if(this.fetchingInitial === false) {
				this.fetchingInitial = true
				this.store.showLoader = true
				let self = this
				axios.post(
					"/post/get-shared-report-data.php",
					JSON.stringify({
						slug: this.shareableLinkSlug,
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if(ret.stations) self.store.stations = ret.stations
					if(ret.stationcombos) self.store.stationcombos = ret.stationcombos
					if(ret.markets) self.store.markets = ret.markets
					let mktIds = []
					if(ret.data) {
						//convert any old stored ones that just had RT=0 to be RT=99
						let hasRT99 = ret.data.find(item => item.audioType === 99)
						if(!hasRT99) {
							for(let item of ret.data) {
								if (item.audioType === 0) {
									item.audioType = 99
									for(let combo in item.combos) {
										item.combos[combo]['audioType'] = 99
										for(let mkt of item.combos[combo].marketSplits) {
											mkt['audioType'] = 99
										}
									}
									for(let mkt of item['marketSplits']) {
										mkt['audioType'] = 99
									}
									for(let st of item['stationResults']) {
										st['audioType'] = 99
									}
								}
							}
						}
						self.store.returnedData = ret.data
						for(let item of ret.data[0]['marketSplits']) {
							if(item.marketId && mktIds.indexOf(item.marketId) === -1) {
								mktIds.push(item.marketId)
							}
						}
					}
					if(ret.viewPreferences) self.prepareViewPrefsFromJob(ret.viewPreferences)
					else self.store.viewPreferences = self.getDefaultViewPreferences()
					self.store.selectionObs.markets = self.store.markets.filter(mkt => mktIds.includes(mkt.id))
					if(ret.sourcing) self.store.returnedDataSourcing = ret.sourcing
					if(ret.inputs) self.store.returnedDataInputs = ret.inputs
					if(ret.userEmail) self.runUserEmail = ret.userEmail
					if(ret.runTime) self.runDateTime = ret.runTime
					self.fetchingInitial = false
					self.fetchingInitialDone = true
					self.store.showLoader = false
					if (ret.error === 1) {
						self.returnedErrorMessage = ret.errob.message
						//self.showKalert(ret.errob)
					}
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	watch: {
		shareableLinkSlug() {
			if(this.shareableLinkSlug.length > 0) {
				this.fetchReportData()
			}
		},
	},
	mounted() {
		document.title = "Audology"
		let params = this.$router.currentRoute.value.params
		if(params.slug && params.slug.length > 0) {
			this.shareableLinkSlug = params.slug
		}
	}
}
</script>

<style scoped>
.note-text-top {
	font-size: 12px;
	color: #888;
	margin-bottom: 20px;
}
</style>