<template>
	<div class="loader-overlay">
		<div class="popup-container">
			<div class="popup-topbar">
				<span class="bold">Demographics List</span>
				<div class="filterbox-hold">
					<input type="text" placeholder="Filter demographics..." v-model="filterText" class="filterbox" />
				</div>
				<span class="closer" @click="store.demosManageOverlayShown = false">
					<i class="mdi mdi-close"></i>
				</span>
			</div>

			<div class="popup-insides" style="text-align: left;">
				<div v-if="fetching">Loading...</div>
				<div v-else>
					<span class="primary-button" @click="editDemographic(0)" >
						<i class="mdi mdi-plus"></i> Create New
					</span>
					<div v-if="store.user.id === 1">
						<p class="bold" style="margin: 20px 0 10px;">Global Demographics</p>
						<div v-for="c in filteredAllUsers" :key="c.id" class="jli">
							<div>
								<span class="jname">{{c.name}}</span>
								<span v-if="store.user.isAdmin === 1" class="jup"> UID: {{c.userid}}
									<span v-if="c.survey_code">, SUID: {{c.survey_code}} </span>
									<span v-if="c.settings.length === 0">[{{c.settings.length}}]</span>
								</span>
							</div>
							<div class="" style="display: flex; justify-content: space-between; width: 100px;">
								<span class="jope" @click="editDemographic(c.id)" title="Edit demographic">EDIT</span>
								<span class="jdel" @click="delDemo(c.id)" title="Delete demographic">DELETE</span>
							</div>
						</div>
					</div>
					<div>
						<div v-if="store.sitesAdmin.length > 0">
							<div v-for="sa in store.sitesAdmin" :key="sa.id" class="">
								<p class="site-group-name">--- {{sa.name}}</p>
								<DemoManageGroup :siteaccessid="sa.id" :demos="getDemosFromSiteId(sa.id)" v-on:deletedemo="delDemo($event)" v-on:editdemo="editDemographic($event)"></DemoManageGroup>
							</div>
						</div>
						<p class="site-group-name" style="margin: 20px 0 10px;">--- User Saved Demographics</p>
						<DemoManageGroup :demos="filteredThisUser" v-on:deletedemo="delDemo($event)" v-on:editdemo="editDemographic($event)"></DemoManageGroup>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import axios from "axios";
import DemoManageGroup from "@/components/demos/DemoManageGroup";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
// import DemoEdit from "@/components/overlays/DemoEdit";
export default {
	name: "DemosManage",
	components: {
		DemoManageGroup
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			fetching: false,
			filterText: '',
		}
	},
	computed: {
		filteredThisUser() {
			let demos = this.store.demographics.filter(item => item.userid === this.store.user.id && item.siteAccessId === 0)
			if(this.filterText.length > 0) {
				return demos.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
			return demos;
		},
		filteredAllUsers() {
			let demos = this.store.demographics.filter(item => item.userid === this.store.user.id && item.siteAccessId === 0)
			if(this.store.user.isAdmin === 1) {
				demos = this.store.demographics.filter(item => item.userid === 0);
				// demos = this.store.demographics.filter(item => item.userid > 0 && item.survey_code === null); //for dev purposes only
			}
			if(this.filterText.length > 0) {
				return demos.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
			return demos;
		},
	},
	methods: {
		getDemosFromSiteId(sid) {
			let demos = this.store.demographics.filter(item => item.userid === this.store.user.id && item.siteAccessId === sid)
			if(this.store.user.isAdmin === 1) {
				demos = this.store.demographics.filter(item => item.userid === 0)
			}
			if(this.filterText.length > 0) {
				return demos.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()))
			}
			return demos
		},
		delDemo(cid) {
			let c = window.confirm("Are you sure you wish to delete this demographic?");
			if(c !== false && this.fetching === false) {
				this.fetching = true;
				let self = this;
				axios.post(
					"/post/demographic-delete.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
						demoid: cid,
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if (ret.resp) {
						self.showKalert(ret.resp)
					}
					self.fetching = false;
					if(ret.deldemoid) {
						self.store.demographics = self.store.demographics.filter(item => item.id !== ret.deldemoid);
					}
				}).catch(function (error) {
					console.log(error);
					self.fetching = false;
				});
			}
		},
	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.site-group-name {
	font-size: 15px;
	font-weight: bold;
	margin-top: 15px;
	margin-bottom: 5px;
	text-align: left;
}
</style>