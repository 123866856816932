<template>
	<div class="loader-overlay">
		<div class="popup-container">
			<div class="popup-topbar">
				<span class="bold">Custom Daypart Editor</span>
				<span class="closer" @click="store.daypartEditOverlayShown = false">
					<i class="mdi mdi-close"></i>
				</span>
			</div>

			<div class="popup-insides" style="text-align: left;">
				<div v-if="fetching">Loading...</div>
				<div v-else>
					<div>
						<p>Due to the differing survey methodologies, you can only create custom dayparts for surveys collected by GFK (Syd, Mel, Bne, Adl, Per, Cbr, GC, Newc).</p>
						<br/>
						<p>Click and drag the mouse button to select multiple.</p>
						<div v-if="store.user.isAdmin === 1 && store.user.id === 1" style="margin-top: 10px;">
							<select v-model="isGlobalDaypart" class="selectbox">
								<option value="true">All Users</option>
								<option value="false">Own User</option>
							</select>
						</div>
						<div v-if="store.sitesAdmin && store.sitesAdmin.length > 0" style="margin-top: 10px;">
							<select v-model="siteAccessId" class="selectbox">
								<option :value="0">Just Me</option>
								<option v-for="site in store.sitesAdmin" :key="site.id" :value="site.id">{{site.name}}</option>
							</select>
						</div>
						<br/>
					</div>

					<div>
						<p style="font-weight: bold; margin-bottom: 5px;">Daypart Name </p>
						<input type="text" v-model="editDaypartName" style="width: 300px; padding: 5px;" />
						<span @click="saveDaypart" class="primary-button" style="margin: 0 20px 0 10px; display: inline-block;">Save</span>
						<span @click="clearAll" style="font-size: 10px; margin: 0 10px 0 0; cursor: pointer; color: #28A; text-decoration: underline">Reset</span>
						<span v-if="store.editDaypartId > 0" @click="deleteDaypart" style="font-size: 10px; margin: 0 10px 0 0; cursor: pointer; color: #28A; text-decoration: underline">Delete</span>
					</div>

					<div style="margin-top: 15px;">
						<div class="dptbl">
							<div class="dprow">
								<div class="dpcell headrow" style="width: 100px;"></div>
								<div class="dpcell headrow" v-for="dow in days" :key="dow">
									{{ucfirst(dow)}}
								</div>
							</div>
							<div class="dprow" v-for="qtr in store.qtrHrs" :key="qtr.id">
								<div class="dpcell" style="width: 100px;">{{qtr.name}} - {{qtr.end}}</div>
								<div class="dpcell" v-for="dow in days" :key="dow"
									:title="ucfirst(dow)+' '+qtr.name+' - '+qtr.end"
									:class="{sel : selections[dow].includes(qtr.id)}"
									@mousedown.left="selectionToggleStart(dow, qtr.id)"
									@mouseover="selectionToggleDrag(dow, qtr.id)"
								>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	name: "DaypartEdit",
	components: {

	},
	mixins: [globalMixin],
	props: {

	},
	data: function () {
		return {
			store: store,
			fetching: false,

			editDaypartName: '',
			selections: {
				sun: [],
				mon: [],
				tue: [],
				wed: [],
				thu: [],
				fri: [],
				sat: [],
			},

			loadFinished: false,
			isGlobalDaypart: false,
			siteAccessId: 0,

			mouseChangeType: '',

			days: ['sun','mon','tue','wed','thu','fri','sat'],

		}
	},
	computed: {
		sourceDaypartOb() {
			if(this.store.editDaypartId === 0) {
				return {
					id: 0,
					name: '',
					userid: this.store.user.id,
					type: 'daypart',
					infolder: 0,
					settings: [],
					siteAccessId: 0,
				}
			}
			return store.dayparts.find(item => item.id === this.store.editDaypartId)
		},
	},
	methods: {
		selectionToggleDrag(dow, qtrid) {
			if(window.mouseIsDown === 1) {
				if(!this.selections[dow].includes(qtrid) && this.mouseChangeType === 'add') this.selections[dow].push(qtrid)
				else if(this.selections[dow].includes(qtrid) && this.mouseChangeType === 'remove') this.selections[dow].splice(this.selections[dow].indexOf(qtrid), 1)
			}
		},
		selectionToggleStart(dow, qtrid) {
			if(!this.selections[dow].includes(qtrid)) this.mouseChangeType = 'add'
			else this.mouseChangeType = 'remove'
			if(!this.selections[dow].includes(qtrid) && this.mouseChangeType === 'add') this.selections[dow].push(qtrid)
			else if(this.selections[dow].includes(qtrid) && this.mouseChangeType === 'remove') this.selections[dow].splice(this.selections[dow].indexOf(qtrid), 1)
		},
		clearAll() {
			this.selections = {
				sun: [],
				mon: [],
				tue: [],
				wed: [],
				thu: [],
				fri: [],
				sat: [],
			}
		},
		saveDaypart() {
			if(this.editDaypartName.length === 0) {
				alert('Please enter a name for your daypart');
				return false;
			}
			if(this.selections.length === 0) {
				alert('Please make some selections');
				return false;
			}
			let self = this;
			self.store.showLoader = true;
			let ownerId = this.store.user.id
			if(this.store.editDaypartId > 0 && this.sourceDaypartOb.owner > 0) { //editing existing
				ownerId = this.sourceDaypartOb.owner
			}
			else {
				if (this.store.user.isAdmin === 1 && this.isGlobalDaypart) ownerId = 0
			}
			axios.post(
				"/post/daypart-save.php",
				JSON.stringify({
					daypartid: self.store.editDaypartId,
					usercreds: self.store.usercreds,
					dname: self.editDaypartName,
					selections: self.selections,
					ownerId: ownerId,
					siteAccessId: self.siteAccessId,
				})
			).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.resp) { //handle returned objects for lists
					self.showKalert(ret.resp)
				}
				if (ret.dayparts) {
					self.store.dayparts = ret.dayparts;
				}
				self.store.showLoader = false;
				self.store.daypartEditOverlayShown = false;
			}).catch(function (error) {
				console.log(error);
				self.store.showLoader = false;
			});
		},
		deleteDaypart() {
			let c = window.confirm("Are you sure you wish to delete this daypart?");
			if(c !== false && this.fetching === false && this.store.editDaypartId > 0) {
				this.fetching = true;
				let self = this;
				axios.post(
					"/post/daypart-delete.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
						daypartid: this.store.editDaypartId,
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if (ret.resp) {
						self.showKalert(ret.resp)
					}
					self.fetching = false;
					if(ret.deldaypid) {
						self.store.daypartEditOverlayShown = false
						self.store.dayparts = self.store.dayparts.filter(item => item.id !== ret.deldaypid);
					}
				}).catch(function (error) {
					console.log(error);
					self.fetching = false;
				});
			}
		},
	},
	watch: {

	},
	mounted() {
		if(this.sourceDaypartOb.qtrHrsByDay) this.selections = this.sourceDaypartOb.qtrHrsByDay
		this.editDaypartName = this.sourceDaypartOb.name
		//determine if this is an All Users daypart (AL only) or just for the active user
		if(this.store.user.isAdmin === 1 && this.sourceDaypartOb.owner === 0) this.isGlobalDaypart = true
		else this.isGlobalDaypart = false
		this.siteAccessId = this.sourceDaypartOb.siteAccessId
		window.setTimeout(function() {
			this.loadFinished = true
		}.bind(this), 1000)
		window.mouseIsDown = 0
	}
}
</script>

<style scoped>
.dptbl {

}
.dprow {
	display: flex;
	user-select: none;
}
.dpcell {
	border-right: 1px solid var(--greymid);
	border-bottom: 1px solid var(--greymid);
	height: 12px;
	display: flex;
	align-items: center;
	width: 40px;
	font-size: 9px;
}
.headrow {
	height: 20px;
	font-size: 12px;
	text-align: center;
}
.headrow.dpcell {
	text-align: center;
	justify-content: center;
	border-top: 1px solid var(--greymid);
}
.dprow .dpcell:first-of-type {
	border-left: 1px solid var(--greymid);
}
.sel {
	background-color: var(--bluemain);
}

</style>