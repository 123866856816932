<template>
	<div>
		<div class="venn-legend">
			<div class="venn-legend-item">
				<div class="venn-legend-item-circle" :style="{backgroundColor: store.vennColors[0]}"></div>
				<div>
					<div class="venn-legend-item-label">{{venndata.data.labels[0]}}</div>
					<div class="venn-legend-val">{{venntotals[0].exclusive}}%</div>
				</div>
			</div>
			<div class="venn-legend-item">
				<div class="venn-legend-item-circle" :style="{backgroundColor: store.vennColors[1]}"></div>
				<div>
					<div class="venn-legend-item-label">{{venndata.data.labels[1]}}</div>
					<div class="venn-legend-val">{{venntotals[1].exclusive}}%</div>
				</div>
			</div>
			<div class="venn-legend-item">
				<div class="venn-legend-item-circle" :style="{backgroundColor: store.vennColors[2]}"></div>
				<div>
					<div class="venn-legend-item-label">{{venndata.data.labels[2]}}</div>
					<div class="venn-legend-val">{{venntotals[2].exclusive}}%</div>
				</div>
			</div>
		</div>
		<div>
			<ChartJSVenn :chartdata="chartjsdata"></ChartJSVenn>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ChartJSVenn from "@/components/charts/ChartJSVenn.vue";
export default {
	name: 'VennCompareThree',
	mixins: [globalMixin],
	components: {
		ChartJSVenn,
	},
	props: {
		venndata: {
			type: Object,
			default: null,
		},
		venntotals: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {
			store: store,

			chartjsdata: null,
		}
	},
	computed: {

	},
	methods: {
		prepData() {
			this.chartjsdata = this.venndata.data
		},
	},
	watch: {
		venndata() {
			if(this.venndata) this.prepData()
		},
	},
	mounted() {
		if(this.venndata) this.prepData()
	},
	created() {

	},
}
</script>

<style scoped>
.venn-legend {
	display: flex;
	justify-content: center;
	gap: 30px;
}
.venn-legend-item {
	display: flex;
	align-items: center;
}
.venn-legend-item-label {
	font-weight: bold;
	margin-bottom: 5px;
	font-size: 16px;
}
.venn-legend-val {
	font-size: 18px;
}
.venn-legend-item-circle {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 10px;
}
</style>
