<template>
	<div class="loader-overlay">
		<div class="popup-container">
			<div class="popup-topbar">
				<span class="bold">Upload Excel Shell/Template</span>
				<span class="closer" @click="$emit('close')">
					<i class="mdi mdi-close"></i>
				</span>
			</div>
			<div class="popup-insides">
				<div>
					<input type="file" @change="handleFile" id="inputFileElement" />
				</div>
				<div style="margin-top: 10px;">
					<span v-if="file1" class="primary-button" @click="submitShellFile()" style="margin-right: 10px;">
						<span v-if="!isUploading">Submit</span>
						<span v-else-if="isUploading">Working...</span>
					</span>
					<span @click="$emit('close')" class="primary-button cancel-button">Cancel</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "PreSchedShellUploader",
	components: {

	},
	props: {},
	emits: ['close','shellimport'],
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			file1: null,
			isUploading: false,

		}
	},
	computed: {},
	methods: {
		handleFile(evt) {
			if(evt.target.files.length) {
				this.file1 = evt.target.files[0]
			}
		},
		submitShellFile() {
			if(this.file1 && this.isUploading === false) {
				this.isUploading = true
				let formData = new FormData()
				formData.append('usercreds', JSON.stringify(this.store.usercreds))
				formData.append('file-upload', this.file1, this.file1.name)
				let targ = '/post/upload-presched-shell.php'
				let self = this
				axios.post(targ, formData).then(function (response) {
					self.file1 = null //reset to null for this
					document.getElementById('inputFileElement').value = null
					let ret = response.data //response.data is returned info
					let mydata = {}
					if (ret.error === 1) {
						self.showKalert(ret.resp)
					}
					else {
						if(ret.preschedRows) mydata.rows = ret.preschedRows
						if(ret.jsonSelections) mydata.jsonSelections = ret.jsonSelections
					}
					self.$emit('shellimport', mydata)
					self.isUploading = false
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.popup-container {
	height: 200px;
	width: 400px;
}
</style>