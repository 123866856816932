<template>
	<div>
		<p class="mktname">
			<span style="cursor: pointer;" v-if="!hiddenmarketids.includes(mktid)" @click="$emit('toggleviewed', mktid)"><i class="mdi mdi-minus"></i></span>
			<span style="cursor: pointer;" v-else @click="$emit('toggleviewed', mktid)"><i class="mdi mdi-plus"></i></span>
			{{market.name}}
		</p>
		<div v-for="stn in filteredStations" :key="stn.id" class="checklist-item" :class="{hidden : hiddenmarketids.includes(mktid)}">
			<div class="ch-and-title" @mousedown.left="toggleStationInSelection(stn)" @mouseover="selectionToggleDrag(stn)">
				<span class="toggler">
					<i v-if="store.selectionObs.stations.some(item => item.id === stn.id)" class="mdi mdi-check-bold"></i>
				</span>
				<span class="label"
						:class="{inactiveStation : !stn.isActive || stn.isActive === false}"
						:title="getStationNameLabel(stn)"
				>
					{{stn.name}}
					<span v-if="stn.netgroupown" class="nettext"> {{stn.netgroupown.toUpperCase()}}</span>
					<span v-if="stn.band && stn.band.toLowerCase().includes('dab')" class="dabtext"> ({{stn.band.toUpperCase()}})</span>
				</span>
			</div>
			<div @click="popoverClicked('stnpop'+stn.id)" class="info small">
				<i class="mdi mdi-information-variant"></i>
			</div>
			<PopoverItem v-if="popoverIdShown === 'stnpop'+stn.id" :title="stn.name" v-on:close="hidePopovers">
				<template v-slot:content>
					<StationPopover :stnob="stn"></StationPopover>
				</template>
			</PopoverItem>
		</div>
	</div>
</template>

<script>
import StationPopover from "@/components/station-selections/StationPopover";
import PopoverItem from "@/components/PopoverItem";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "StationMarketGroup",
	components: {
		PopoverItem,
		StationPopover
	},
	mixins: [globalMixin],
	props: {
		mktid: Number,
		filtertext: String,
		hiddenmarketids: Array,
	},
	data: function () {
		return {
			store: store,
			popoverIdShown: null,
		}
	},
	computed: {
		market() {
			return this.store.markets.find(item => item.id === this.mktid);
		},
		filteredStations() {
			let mystations = [];
			if(this.store.user.viewCommunities === 1 && this.store.showPrivateStations === true) mystations = this.store.stations.filter(item => this.mktid === item.mktid);
			else mystations = this.store.stations.filter(item => this.mktid === item.mktid && item.community === false);
			if(this.store.showDabStations === false) {
				mystations = mystations.filter(item => !item.band.toLowerCase().includes('dab') && item.name.toLowerCase() !== 'o-digital');
			}
			if(this.filtertext.length === 0) return mystations;
			else { //either already selected or matching text
				return mystations.filter(item => this.store.selectionObs.stations.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filtertext.toLowerCase()));
			}
		},
	},
	methods: {
		popoverClicked(tid) {
			this.popoverIdShown = tid
		},
		hidePopovers() {
			this.popoverIdShown = null
		},
		selectionToggleDrag(elem) {
			if(window.mouseIsDown === 1) {
				this.addStationToSelection(elem)
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.mktname {
	font-weight: bold;
	margin: 10px 0 5px 0;
}
.checklist-item.hidden {
	display: none;
}
</style>