<template>
	<div class="loader-overlay">
		<div class="popup-container">
			<div class="popup-topbar">
				<span class="bold">Export Template Settings</span>
				<span class="closer" @click="$emit('close')">
					<i class="mdi mdi-close"></i>
				</span>
			</div>
			<div class="popup-insides">
				<div>
					<p class="mainer">If you're not sure which settings to choose, leave the defaults below.</p>
					<div class="checkbox-container">
						<label class="checkbox-label">
							<input type="checkbox" v-model="includeTotalRadio">
							<span class="checkbox-text">
								<span class="bold">Export rows for Total Radio type.</span>
							</span>
						</label>
						<br/>
						<label class="checkbox-label">
							<input type="checkbox" v-model="includeAMFMDAB">
							<span class="checkbox-text">
								<span class="bold">Export rows for AM/FM/DAB Radio type.</span>
							</span>
						</label>
						<br/>
						<label class="checkbox-label">
							<input type="checkbox" v-model="includeStreaming">
							<span class="checkbox-text">
								<span class="bold">Export rows for Streaming Radio type.</span>
							</span>
						</label>
						<div style="font-size:12px; line-height: 1.4; margin-top: 5px;">
							If your campaign splits Total Radio, AM/FM/DAB+ and Streaming check the appropriate boxes.<br/>
							If your campaign only uses Total Radio you can just leave Total Radio box checked.
						</div>
					</div>
					<div class="checkbox-container">
						<label class="checkbox-label">
							<input type="checkbox" v-model="onlycreatedrows">
							<span class="checkbox-text">
								<span class="bold">Export only rows that have been created manually.</span>
								<br/>
								If you check this box it will only export rows you created/see in the Audology web UI.<br/>
								If you uncheck this box it will create rows for all permutations of the Stations/Dayparts/RadioTypes you've selected.<br/>
								Only check this if you have already created the rows you need and wish to export any data you've already added.<br/>
							</span>
						</label>
					</div>
					<div class="checkbox-container">
						<label class="checkbox-label">
							<input type="checkbox" v-model="includeconfig">
							<span class="checkbox-text">
								<span class="bold">Include configuration settings.</span>
								<br/>
								By default your market, survey, and demo selections can be exported with the template (making it easier to share or import later).<br/>
								If you don't wish to overwrite your market/survey/demo selections upon import, uncheck this box.
							</span>
						</label>
					</div>
				</div>
				<div style="margin-top: 10px;">
					<span class="primary-button" @click="exportSettings()" style="margin-right: 10px;">
						<span>Submit</span>
					</span>
					<span @click="$emit('close')" class="primary-button cancel-button">Cancel</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins";

export default {
	name: "PreSchedExportPrefs",
	components: {

	},
	props: {},
	emits: ['close','doexport'],
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,

			onlycreatedrows: false,
			includeconfig: true,

			includeTotalRadio: true,
			includeAMFMDAB: false,
			includeStreaming: false,

		}
	},
	computed: {},
	methods: {
		exportSettings() {
			let settings = {
				includeTotalRadio: this.includeTotalRadio,
				includeAMFMDAB: this.includeAMFMDAB,
				includeStreaming: this.includeStreaming,
				onlycreatedrows: this.onlycreatedrows,
				includeconfig: this.includeconfig,
			}
			this.$emit('doexport', settings)
		}
	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.popup-container {
	height: 540px;
	width: 600px;
}
.mainer {
	font-size: 14px;
	line-height: 1.4;
	font-weight: bold;
	margin-bottom: 20px;
}
.checkbox-container {
	margin-bottom: 20px;
}
.checkbox-text {
	font-size: 12px;
	line-height: 1.4;
}
.checkbox-text .bold {
	font-weight: bold;
	font-size: 13px;
}
</style>