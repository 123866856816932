<template>
	<div v-if="filteredJobsInFolder && filteredJobsInFolder.length > 0">
		<div style="text-align: left; margin-top: 10px; font-size: 12px; text-transform: uppercase; padding: 5px 0; font-weight: bold;" class="">
			<span v-if="folderob">{{folderob.name}}</span>
			<i v-if="filteredJobsInFolder.length === 0 && folderob.id > 0" class="mdi mdi-close" @click="$emit('deletefolder')" style="color: #B00; cursor: pointer;"></i>
		</div>
		<div v-for="c in filteredJobsInFolder" :key="c.id" class="jli">
			<div>
				<span class="jname">{{c.name}}</span>
				<span v-if="!isOwner(c)" class="jup" style="margin-left:5px;"> {{getUserEmailById(c.ownerId)}}</span>
				<span class="jup" style="margin-right: 5px;"> ({{c.tstamp}})</span>
				<i v-if="isOwner(c)" class="mdi mdi-pencil-outline" title="Edit job name" @click="startRenameJob(c)"></i>
			</div>
			<div class="" style="display: flex; justify-content: flex-end; width: 200px;">
				<span class="jop" @click="$emit('openjob', c.id)" title="Open job">OPEN</span>
				<span class="jope" @click="shareJobPre(c)" title="Share job">SHARE</span>
				<span v-if="sharetype === 'none'" class="jope" @click="changeFolder(c)" title="Change folder">FOLDER</span>
				<span v-if="isOwner(c)" class="jdel" @click="$emit('deletejob', c.id)" title="Delete job">DELETE</span>
			</div>
		</div>

		<div v-if="showingFolderChooser" class="loader-overlay">
			<div class="popup-container folder-chooser">
				<p class="bold" style="margin: 10px 0;">Choose Folder</p>
				<div v-if="!showAddFolder">
					<select v-model="chosenFolderId" class="selectbox">
						<option value="0">Unassigned</option>
						<option v-for="df in store.jobfolders" :key="df.id" :value="df.id">{{df.name}}</option>
					</select>
					<span @click="saveToFolder" class="primary-button" style="margin: 0px 10px 0px 10px; display: inline-block;">Save</span>
					<span @click="showingFolderChooser = false" class="primary-button cancel-button" style="margin: 0; display: inline-block;">Cancel</span>
					<span @click="showAddFolder = true" class="primary-button" style="margin: 0px 20px 0px 20px; display: inline-block;">New Folder</span>
				</div>
				<div style="padding: 0 0 20px 0;">
					<div v-if="showAddFolder" style="display: inline-block;">
						<input type="text" style="width: 300px; padding: 5px;" v-model="newFolderName">
						<span @click="createNewFolder" class="primary-button" style="margin: 0px 10px 0px 10px; display: inline-block;">Save</span>
						<span @click="showAddFolder = false" class="primary-button cancel-button" style="margin: 0; display: inline-block;">Cancel</span>
					</div>
				</div>
			</div>
		</div>

		<div v-if="showingShareOverlay" class="loader-overlay">
			<div class="popup-container share-chooser">
				<p class="bold" style="margin: 10px 0;">Share Job with another user</p>
				<p class="share-heading">Enter Audology user email address to share with</p>
				<div>
					<input type="text" class="share-with-email-input" style="" v-model="sharingWithEmail">
				</div>
				<div v-if="canEditSharingJob" style="margin: 20px 0 0;">
					<p class="share-heading">
						Should this user be able to edit or only view your job?
						It's recommended you allow View Only (the recipient will still be able to save a copy in their own account but not overwrite yours).
					</p>
					<select v-model="sharingUserCanEdit" class="selectbox">
						<option v-for="c in viewEditChoices" :key="c.value" :value="c.value">{{c.text}}</option>
					</select>
				</div>
				<div style="margin-top: 20px;">
					<span @click="doShare" class="primary-button" style="margin: 0 10px 0 0; display: inline-block;">Share</span>
					<span @click="cancelShare" class="primary-button cancel-button" style="margin: 0; display: inline-block;">Cancel</span>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import axios from "axios";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "JobManageFolder",
	components: {},
	mixins: [globalMixin],
	props: {
		jobs: Array,
		folderob: Object,
		sharetype: String,
	},
	data: function () {
		return {
			store: store,
			showingFolderChooser: false,
			chosenFolderId: 0,
			activeJobId: 0,

			showAddFolder: false,
			newFolderName: '',

			showingShareOverlay: false,
			sharingJob: null,
			sharingUserCanEdit: 0,
			sharingWithEmail: '',
		}
	},
	computed: {
		filteredJobsInFolder() {
			if(this.sharetype === 'none') { //normal jobs list (owned by me)
				return this.jobs.filter(item => item.infolder === this.folderob.id && item.receiveShareType === null);
			}
			else if(this.sharetype === 'shared') { //shared jobs list
				return this.jobs
			}
			else if(this.sharetype === 'site') { //site jobs list for site admin
				//return this.jobs.filter(item => item.infolder === this.folderob.id && item.ownerId === this.store.user.id);
			}
			return []
		},
		userIsAlan() {
			if(this.store.user.id === 1 || this.store.user.id === 1456) return true
			return false
		},
		canEditSharingJob() {
			if(this.sharingJob === null) return false
			if(this.sharingJob.ownerId === this.store.user.id || this.userIsAlan) return true
			return false
		},
		viewEditChoices() {
			if(this.canEditSharingJob === true) {
				return [
					{value: 0, text: 'View Only'},
					{value: 1, text: 'Edit'},
				]
			}
			else {
				return [
					{value: 0, text: 'View Only'},
				]
			}
		},
	},
	methods: {
		isOwner(job) {
			if(job.ownerId === this.store.user.id || this.userIsAlan) return true
			return false
		},
		shareJobPre(jobOb) {
			this.sharingJob = jobOb
			this.sharingWithEmail = ''
			this.sharingUserCanEdit = 0
			this.showingShareOverlay = true
		},
		doShare() {
			if(this.sharingJob && this.sharingWithEmail !== '') {
				let self = this
				axios.post(
					"/post/job-share.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
						jobId: self.sharingJob.id,
						shareWithEmail: self.sharingWithEmail,
						canEdit: self.sharingUserCanEdit,
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if (ret.resp) self.showKalert(ret.resp)
					self.cancelShare()
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		cancelShare() {
			this.showingShareOverlay = false
			this.sharingJob = null
			this.sharingWithEmail = ''
			this.sharingUserCanEdit = 0
		},
		createNewFolder() {
			if(this.newFolderName.length > 0) {
				let self = this;
				axios.post(
					"/post/job-folder-create.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
						name: self.newFolderName,
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if (ret.resp) {
						self.showKalert(ret.resp)
					}
					if(ret.jobfolders) self.store.jobfolders = ret.jobfolders;
					self.showAddFolder = false;
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		changeFolder(cob) {
			this.chosenFolderId = cob.infolder;
			this.activeJobId = cob.id;
			this.showingFolderChooser = true;
		},
		saveToFolder() {
			if(this.activeJobId > 0) {
				let self = this;
				axios.post(
					"/post/job-change-folder.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
						jobid: self.activeJobId,
						folderid: self.chosenFolderId,
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if (ret.resp) {
						self.showKalert(ret.resp)
					}
					self.showingFolderChooser = false;
					if(ret.jobfolders) self.store.jobfolders = ret.jobfolders;
					if(ret.jobs) self.store.jobs = ret.jobs;
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		startRenameJob(jobOb) {
			let c = window.prompt("Enter new job name", jobOb.name);
			if(c !== false && c.length > 0) {
				let self = this
				axios.post(
					"/post/job-rename.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
						jobid: jobOb.id,
						name: c,
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if (ret.resp) {
						self.showKalert(ret.resp)
						if(ret.resp.jobid) {
							self.$emit('changename', {jid: ret.resp.jobid, newname: c});
						}
					}
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.popup-container.folder-chooser {
	height: 100px;
}
.popup-container.share-chooser {
	height: 280px;
	padding: 10px 20px;
}
.share-with-email-input {
	width: 300px;
	padding: 5px;
	border: 1px solid var(--greymid);
	border-radius: 5px;
}
.share-heading {
	margin: 10px 0;
}
</style>