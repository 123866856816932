<template>
	<div>
		<DemoManageFolder v-for="folder in folderList" :key="folder.id"
			:folderob="folder" :demos="demos"
			v-on:deletedemo="$emit('deletedemo', $event)"
			v-on:editdemo="$emit('editdemo', $event)"
			v-on:deletefolder="deleteFolder(folder.id)"
		>
		</DemoManageFolder>
	</div>
</template>

<script>
import DemoManageFolder from "@/components/demos/DemoManageFolder";
import axios from "axios"
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "DemoManageGroup",
	components: {
		DemoManageFolder
	},
	mixins: [globalMixin],
	props: {
		demos: Array,
		siteaccessid: {
			type: Number,
			default: 0,
		},
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		folderList() {
			let out = [];
			for(let i=0; i<this.store.demofolders.length; i++) {
				let fold = this.store.demofolders[i];
				if(fold.siteAccessId === this.siteaccessid) out.push({id: fold.id, name: fold.name})
			}
			out.push({id: 0, name: 'Unsorted'});
			return out;
		},
	},
	methods: {
		deleteFolder(fid) {
			let c = window.confirm("Are you sure you wish to delete this folder?");
			if(c !== false && fid > 0) {
				let self = this;
				axios.post(
					"/post/demo-folder-delete.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
						demofolderid: fid,
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if(ret.demofolders) self.store.demofolders = ret.demofolders
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>