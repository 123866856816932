<template>
	<div class="menubar">
		<div class="menu-insides">
			<div style="display: flex; align-items: center;">
				<router-link to="/">
					<img src="@/assets/audology-rev.svg" alt="Logo" class="top-logo" title="Click to start new report" @click="unsetReportType" />
				</router-link>
			</div>
			<div class="menu-items">
				<TopMenuGroup label="System" :hasdropdown="true">
					<TopMenuItem label="New Report" @click="unsetReportType"></TopMenuItem>
<!--					<TopMenuItem @click="toggleRunAsHighlightsFlag" label="Highlights Run"></TopMenuItem>-->
					<TopMenuItem label="Help & Glossary" @click="goToHelp"></TopMenuItem>
					<TopMenuItem label="Tutorial Videos" @click="goToTutorialVid"></TopMenuItem>
					<TopMenuItem label="Log Out" @click="logout"></TopMenuItem>
				</TopMenuGroup>
				<TopMenuGroup label="Jobs" :hasdropdown="true">
					<TopMenuItem label="New Job" @click="newJob"></TopMenuItem>
					<TopMenuItem label="Open & Manage" @click="store.jobManageOverlayShown = true"></TopMenuItem>
					<TopMenuItem label="Save" @click="saveJob"></TopMenuItem>
					<TopMenuItem label="Copy To New" @click="saveToNewJob"></TopMenuItem>
<!--					<TopMenuItem label="Batches" @click="store.batchManageOverlayShown = true"></TopMenuItem>-->
				</TopMenuGroup>
				<TopMenuGroup label="Manage" :hasdropdown="true">
					<TopMenuItem label="Manage Demographics" @click="store.demosManageOverlayShown = true"></TopMenuItem>
					<TopMenuItem label="Manage Combos" @click="store.combosManageOverlayShown = true"></TopMenuItem>
					<TopMenuItem label="Manage Dayparts (GFK)" @click="store.daypartManageOverlayShown = true"></TopMenuItem>
				</TopMenuGroup>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import TopMenuGroup from "@/components/TopMenuGroup";
import TopMenuItem from "@/components/TopMenuItem";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "MenuBar",
	components: {
		TopMenuItem,
		TopMenuGroup
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			openMenu: false,
			jobIsSaving: false,
		}
	},
	computed: {},
	methods: {
		logout() {
			let c = window.confirm("Are you sure you wish to log out?  This will mean you need to add your password in at the login screen next time.  Unless you are on a shared or public computer you can just close the browser window, and we'll remember you.");
			if(c !== false) {
				let newusr = {
					id: 0,
					email: '',
					token: '',
					isAdmin: null,
				};
				this.clearLocalStorage(); //unset storage for useremail & usertoken
				this.setUser(newusr);
				this.store.usercreds = {id: 0, token: ''}
				this.store.reportType = null
				this.store.activeTab = null
				this.store.selectionObs = {
					markets: [],
					stations: [],
					demographics: [],
					statistics: [],
					dayparts: [],
				}
				this.store.returnedData = []
				this.store.rfConfig = this.getDefaultRFConfig()
				this.$router.push('/login?loggedout=1');
			}
		},
		goToHelp() {
			window.open("https://kb.audology.app","_blank");
		},
		goToTutorialVid() {
			window.open("https://kb.audology.app/tutorial-videos","_blank");
		},
		saveToNewJob() {
			let jname = prompt("Please enter a name for your job");
			if(jname.length > 0 && jname != "" && jname != null && jname !== 'null') {
				this.store.jobid = 0;
				this.store.jobname = jname;
				this.saveJob();
			}
		},
		saveJob() {
			if(this.jobIsSaving === false) {
				this.jobIsSaving = true;
				let self = this;
				let jobname = self.store.jobname;
				if(this.store.jobid === 0 && jobname.length === 0) {
					jobname = prompt("Please enter a name for your job");
					if(jobname.length === 0 || jobname === "" || jobname == null || jobname === 'null') {
						return false;
					}
				}
				if(this.store.reportType === 'reachFrequency' && this.store.rfConfig && this.store.rfConfig.type === 'precampaign') {
					this.syncPreCampaignToRfConfig()
				}
				console.log(self.store.shareableLinkSlug)
				axios.post(
					"/post/job-save.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
						jid: self.store.jobid,
						jobname: jobname,
						jobjson: {
							surveys: self.prepareSurveySetForSend(true),
							markets: self.getObIdsToArr(self.store.selectionObs.markets),
							stations: self.getObIdsToArr(self.store.selectionObs.stations),
							demographics: self.getObIdsToArr(self.store.selectionObs.demographics),
							statistics: self.getObIdsToArr(self.store.selectionObs.statistics),
							dayparts: self.getObIdsToArr(self.store.selectionObs.dayparts),
							layout: self.store.tableLayout,
							splitQtr: self.store.splitByQuarterHours,
							splitQtrType: self.store.qtrHrSplitType,
							splitDOW: self.store.splitByDayOfWeek,
							splitPOL: self.store.splitByPlaceOfListen,
							splitAudType: self.store.splitByAudioType,
							viewPreferences: self.store.viewPreferences,
							dupeVenn1: self.store.dupeVenn1,
							dupeVenn2: self.store.dupeVenn2,
							dupeVenn3: self.store.dupeVenn3,
							shareableLinkSlug: self.store.shareableLinkSlug,
						},
						reportType: self.store.reportType,
						rfjson: JSON.stringify(self.store.rfConfig),
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if (ret.resp) { //handle returned objects for lists
						self.showKalert(ret.resp)
						if(ret.resp.jobid > 0) {
							self.store.jobid = ret.resp.jobid;
						}
					}
					self.jobIsSaving = false;
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>
<style>

</style>

<style scoped>
.menubar {
	background-color: var(--bluedark);
	margin: 0;
	color: #FFF;
	font-size: 12px;
	font-family: 'Roboto';
	font-weight: bold;
	top: 0;
	z-index: 997;
	position: fixed;
	width: 100%;
	height: 40px;
}
.menu-insides {
	display: flex;
	height: 100%;
	align-content: center;
}
.top-logo {
	margin-top: 3px;
	height: 24px;
	width: 160px;
	cursor: pointer;
}
.menu-items {
	margin-left: 20px;
	color: #FFF;
	cursor: pointer;
	display: flex;
	gap: 10px;
	align-items: center;
}
.menu-list {
	list-style-type: none;
	margin: 0;
	display: flex;
}

</style>