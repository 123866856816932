<template>
	<div>
		<DaypartManageFolder  v-for="folder in folderList" :key="folder.id"
			:folderob="folder" :dayparts="dayparts"
			v-on:deletedaypart="$emit('deletedaypart', $event)"
			v-on:deletefolder="deleteFolder(folder.id)"
		>
		</DaypartManageFolder>
	</div>
</template>

<script>
import axios from "axios"
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import DaypartManageFolder from "@/components/dayparts/DaypartManageFolder.vue";
export default {
	name: "DaypartsManageGroup",
	components: {
		DaypartManageFolder,
	},
	mixins: [globalMixin],
	props: {
		dayparts: Array,
		siteaccessid: {
			type: Number,
			default: 0,
		},
	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		folderList() {
			let out = [];
			for(let i=0; i<this.store.daypartfolders.length; i++) {
				let fold = this.store.daypartfolders[i]
				if(fold.siteAccessId === this.siteaccessid) out.push({id: fold.id, name: fold.name})
			}
			out.push({id: 0, name: 'Unsorted'})
			return out;
		},
	},
	methods: {
		deleteFolder(fid) {
			let c = window.confirm("Are you sure you wish to delete this folder?");
			if(c !== false && fid > 0) {
				let self = this;
				axios.post(
					"/post/daypart-folder-delete.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
						daypartfolderid: fid,
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if(ret.daypartfolders) self.store.daypartfolders = ret.daypartfolders
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>