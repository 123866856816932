<template>
	<div class="export-button" :class="{dropdownactive : dropdownactive}">
		<div class="export-button-top" @click="toggleDropdown">
			<span>{{title}}</span>
			<i v-if="!dropdownactive" class="mdi mdi-chevron-down"></i>
			<i v-if="dropdownactive" class="mdi mdi-chevron-up"></i>
		</div>
		<div class="export-button-bottom">
			<slot name="choices"></slot>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: 'ExportButton',
	mixins: [globalMixin],
	props: {
		title: {
			type: String,
			default: 'Export'
		},
	},
	data: function () {
		return {
			store: store,
			dropdownactive: false,
			openingDropdown: false,
		}
	},
	computed: {

	},
	methods: {
		toggleDropdown() {
			if(!this.dropdownactive) {
				this.openingDropdown = true
				setTimeout(() => {
					this.openingDropdown = false
				}, 200)
			}
			this.dropdownactive = !this.dropdownactive
		},
	},
	watch: {
		'store.mouseClickAnywhere': function() {
			if(this.dropdownactive && !this.openingDropdown) this.dropdownactive = false
		}
	}
}
</script>

<style scoped>
.export-button {
	color: #FFF;
	cursor: pointer;
	transition: opacity 0.2s ease-in-out;
	display: inline-block;
	position: relative;
	font-size: 12px;
	margin-right: 10px;
}
.export-button-top {
	background-color: var(--bluemain);
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 15px;
	padding: 5px 8px;
	border-radius: 5px;
	width: 80px;
}
.export-button-top i {
	font-size: 16px;
}
.export-button-bottom {
	display: none;
	background-color: #FFF;
	position: absolute;
	border: 1px solid var(--greymid);
	padding: 5px;
	z-index: 1;
	width: 200px;
	color: var(--bluemain);
	box-shadow: 0 0 5px rgba(0,0,0,0.2);
}
.export-button.dropdownactive .export-button-bottom {
	display: block;
}

</style>
