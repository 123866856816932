<template>
	<div class="loader-overlay">
		<div class="popup-container">
			<div class="popup-topbar">
				<span class="bold">User Dayparts List</span>
				<div class="filterbox-hold">
					<input type="text" placeholder="Filter dayparts..." v-model="filterText" class="filterbox" />
				</div>
				<span class="closer" @click="store.daypartManageOverlayShown = false">
					<i class="mdi mdi-close"></i>
				</span>
			</div>

			<div class="popup-insides" style="text-align: left;">
				<div v-if="fetching">Loading...</div>
				<div v-else>
					<span class="primary-button" @click="editDaypart(0)" >
						<i class="mdi mdi-plus"></i> Create New
					</span>
					<br/><br/>
					<div>
						<div v-if="store.sitesAdmin.length > 0">
							<div v-for="sa in store.sitesAdmin" :key="sa.id" class="">
								<p class="site-group-name">--- {{sa.name}}</p>
								<DaypartsManageGroup :siteaccessid="sa.id" :dayparts="getDaypartsFromSiteId(sa.id)" v-on:deletedaypart="delDaypart($event)"></DaypartsManageGroup>
							</div>
						</div>
						<p class="site-group-name" style="margin: 20px 0 10px;">--- User Saved Dayparts</p>
						<DaypartsManageGroup :dayparts="filteredThisUser" v-on:deletedaypart="delDaypart($event)"></DaypartsManageGroup>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import axios from "axios";
import DaypartsManageGroup from "@/components/dayparts/DaypartsManageGroup";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"

export default {
	name: "DaypartsManage",
	components: {
		DaypartsManageGroup
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			fetching: false,
			filterText: '',
		}
	},
	computed: {
		filteredThisUser() {
			let dayparts = this.store.dayparts.filter(item => item.owner === this.store.user.id && item.siteAccessId === 0)
			if(this.filterText.length > 0) {
				return dayparts.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()));
			}
			return dayparts
		},
	},
	methods: {
		getDaypartsFromSiteId(sid) {
			let dayparts = this.store.dayparts.filter(item => item.owner === this.store.user.id && item.siteAccessId === sid)
			if(this.filterText.length > 0) {
				return dayparts.filter(item => item.name.toLowerCase().includes(this.filterText.toLowerCase()))
			}
			return dayparts
		},
		delDaypart(cid) {
			let c = window.confirm("Are you sure you wish to delete this daypart?");
			if(c !== false && this.fetching === false) {
				this.fetching = true
				let self = this
				axios.post(
					"/post/daypart-delete.php",
					JSON.stringify({
						usercreds: self.store.usercreds,
						daypartid: cid,
					})
				).then(function (response) {
					let ret = response.data //response.data is returned info
					if (ret.resp) {
						self.showKalert(ret.resp)
					}
					self.fetching = false;
					if(ret.deldaypid) {
						self.store.dayparts = self.store.dayparts.filter(item => item.id !== ret.deldaypid);
					}
				}).catch(function (error) {
					console.log(error);
					self.fetching = false;
				});
			}
		},
	},
	watch: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.site-group-name {
	font-size: 15px;
	font-weight: bold;
	margin-top: 15px;
	margin-bottom: 5px;
	text-align: left;
}
</style>