<template>
	<div class="surveysMarketGroup">
		<p class="mktname">
			<span style="cursor: pointer;" v-if="!hiddenmarketids.includes(mktid)" @click="$emit('toggleviewed', mktid)"><i class="mdi mdi-minus"></i></span>
			<span style="cursor: pointer;" v-else @click="$emit('toggleviewed', mktid)"><i class="mdi mdi-plus"></i></span>
			{{market.name}}
		</p>
		<div v-for="sv in filteredSurveys" :key="sv.id" class="checklist-item" :class="{hidden : hiddenmarketids.includes(mktid)}"
			@mousedown.left="selectionToggleStart(sv)" @mouseover="selectionToggleDrag(sv)">
			<div class="" :class="{surveyIsChosen : allsurveysactive.includes(sv)}">
				<span class="label">
					<span>{{sv.name}}</span>
				</span>
			</div>
			<span class="sswarn" v-if="reportType === 'reachFrequency' && sv.quota < 600 && !sv.isGfk">Sample size too small for R&F</span>
			<span class="sswarn" v-if="sv.userCanViewFull !== true" title="Your account will not be able to run results on this survey due to data access limitations.">No access</span>
			<InfoIcon addclasses="small" :id="sv.id" @click="popoverClicked(sv.id)"></InfoIcon>
			<PopoverItem v-if="popoverIdShown === sv.id" :title="sv.name" v-on:close="hidePopovers">
				<template v-slot:content>
					<p><span class="api-info">API ID: {{sv.id}}</span></p>
					<p><span class="bold">Released: </span>{{sv.reldate}}</p>
					<p v-if="sv.quota > 0"><span class="bold">Sample: </span> {{sv.quota}}</p>
				</template>
			</PopoverItem>
		</div>
	</div>
</template>

<script>
import InfoIcon from "@/components/InfoIcon";
import PopoverItem from "@/components/PopoverItem";
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "SurveysMarketGroup",
	components: {
		PopoverItem,
		InfoIcon,
	},
	mixins: [globalMixin],
	props: {
		mktid: Number,
		filtertext: String,
		hiddenmarketids: Array,
		allsurveysactive: Array,
	},
	data: function () {
		return {
			store: store,
			popoverIdShown: null,
		}
	},
	computed: {
		reportType() {
			return this.store.reportType;
		},
		market() {
			return this.store.markets.find(item => item.id === this.mktid);
		},
		filteredSurveys() {
			let mysurvs =  this.store.surveys.filter(item => this.mktid === item.mktid);
			if(this.filtertext.length === 0) return mysurvs
			else {
				return mysurvs.filter(item => this.store.selectionObs.surveys.some(elem => elem.id === item.id) || item.name.toLowerCase().includes(this.filtertext.toLowerCase()));
			}
		},
	},
	methods: {
		popoverClicked(tid) {
			this.popoverIdShown = tid
		},
		hidePopovers() {
			this.popoverIdShown = null
		},
		selectionToggleDrag(elem) {
			if(window.mouseIsDown === 1) {
				this.$emit('checktoggle', {svob: elem})
			}
		},
		selectionToggleStart(elem) {
			this.$emit('checktoggle', {svob: elem})
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.mktname {
	font-weight: bold;
	margin: 10px 0 5px 0;
}
.surveysMarketGroup:first-of-type .mktname {
	margin-top: 0;
}
.sswarn {
	font-size: 11px;
	color: var(--rederror);
	font-style: italic;
	margin-left: 5px;
}
.checklist-item.hidden {
	display: none;
}
.label .mdi {
	cursor: pointer;
	font-size: 18px;
	padding: 5px;
}
.label span {
	cursor: pointer;
}
.label span:hover {
	text-decoration: underline;
}
</style>